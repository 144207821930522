<template>
  <div class="button-group-container">
    <!-- Botones de estadísticas centrados -->
    <router-link to="/estadisticasPosible">
      <Button class="p-button p-button-outlined p-button-primary" label="Gráfico Posibles Clientes" icon="pi pi-chart-bar" />
    </router-link>
    <router-link to="/estadisticasRedondo">
      <Button class="p-button p-button-outlined p-button-secondary" label="Gráfico" icon="pi pi-chart-pie" />
    </router-link>
    <router-link to="/estadisticasClick">
      <Button class="p-button p-button-outlined p-button-help" label="Estadística de Me gusta" icon="pi pi-thumbs-up" />
    </router-link>
    <router-link to="/estadisticasComentario">
      <Button class="p-button p-button-outlined p-button-info" label="Comentarios Tradicionales" icon="pi pi-comments" />
    </router-link>
    <router-link to="/estadisticasComentarioMini">
      <Button class="p-button p-button-outlined p-button-success" label="Comentarios Minimalista" icon="pi pi-comment" />
    </router-link>
    <router-link to="/estadisticasComentarioTransi">
      <Button class="p-button p-button-outlined p-button-warning" label="Comentarios Transicional" icon="pi pi-comments" />
    </router-link>
  </div>
</template>

<script>
import Button from 'primevue/button';

export default {
  name: 'ButtonGroup',
  components: { Button }
};
</script>

<style scoped>
/* Estilo para centrar y organizar los botones */
.button-group-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin-bottom: 20px;
}

.p-button {
  font-size: 1rem;
  width: 250px;
  text-align: center;
}
</style>

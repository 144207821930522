<template>
	<div class="layout-topbar d-flex align-items-center justify-content-between p-2 shadow-sm" style="background-color: #343a40; color: white;">
		<div class="d-flex align-items-center ms-3">
			<img src="@/assets/logoVeraJaus.png" alt="Image" height="50" class="mb-1 me-2" style="border-radius:10px;">
			<span class="fs-4 fw-bold text-white">VERA JAUS</span>
		</div>

		<div class="d-flex align-items-center">
			<button class="p-link layout-menu-button layout-topbar-button me-3 text-white" @click="onMenuToggle">
				<i class="pi pi-bars fs-4"></i>
			</button>

			<button class="p-link layout-topbar-menu-button layout-topbar-button me-3 text-white"
				v-styleclass="{ selector: '@next', enterClass: 'hidden', enterActiveClass: 'scalein', 
				leaveToClass: 'hidden', leaveActiveClass: 'fadeout', hideOnOutsideClick: true}">
				<i class="pi pi-ellipsis-v fs-4"></i>
			</button>

			<ul class="layout-topbar-menu list-unstyled mb-0 d-none d-lg-flex align-items-center">
				<li class="me-3">
					<button class="p-link layout-topbar-button d-flex flex-column align-items-center text-white">
						<i class="pi pi-calendar fs-5"></i>
						<span class="small">Events</span>
					</button>
				</li>
				<li class="me-3">
					<button class="p-link layout-topbar-button d-flex flex-column align-items-center text-white">
						<i class="pi pi-cog fs-5"></i>
						<span class="small">Settings</span>
					</button>
				</li>
				<li>
					<div class="dropdown">
						<button class="p-link layout-topbar-button dropdown-toggle d-flex flex-column align-items-center text-white" type="button" data-bs-toggle="dropdown" aria-expanded="false">
							<i class="pi pi-user fs-5"></i>
						</button>
						<ul class="dropdown-menu dropdown-menu-end">
							<li><a class="dropdown-item" href="#" @click="logout">Cerrar Sesión</a></li>
						</ul>
					</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { logout } from '@/services/auth.service';

export default {
	data() {
		return {};
	},
	methods: {
		onMenuToggle(event) {
			this.$emit('menu-toggle', event);
		},
		onTopbarMenuToggle(event) {
			this.$emit('topbar-menu-toggle', event);
		},
		logout() {
			logout()
				.then(() => {
					this.$router.push('/login');
				})
				.catch(error => {
					console.error(error);
				});
		},
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
};
</script>

<style scoped>
.layout-topbar {
	background-color: #343a40;
	color: white;
}
.layout-topbar-button {
	color: white;
	transition: background-color 0.3s, color 0.3s;
}
.layout-topbar-button:hover {
	background-color: #495057;
	color: white;
}
.layout-topbar-menu .p-link {
	color: white;
}
.layout-topbar-menu .p-link:hover {
	color: #adb5bd;
}
</style>

<template>
  <div class="container mt-4 listas-container">
    <div class="header-section text-center">
      <h1 class="mb-4">Lista de Usuarios</h1>
    </div>

    <div class="search-section mb-4">
      <input type="text" v-model="filtroBusqueda" placeholder="Buscar usuarios" class="form-control search-input" />
      <button class="btn btn-primary ms-2" @click="ordenarPorNombres">
        Ordenar por Nombres
        <i class="fas" :class="ordenAscendente ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"></i>
      </button>
      <router-link to="/registroadmin">
        <button type="button" class="btn btn-primary ms-2">Registrar Cuentas</button>
      </router-link>
      <button class="btn btn-secondary ms-2" @click="descargarPDF">Descargar PDF</button>
    </div>

    <div class="table-responsive">
      <table id="tablaUsuarios" class="table custom-table">
        <thead class="bg-primary text-white">
          <tr>
            <th>ID</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Correo</th>
            <th>Teléfono</th>
            <th>Dirección</th>
            <th>Empresa</th>
            <th>Actividad</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="usuario in usuariosFiltrados" :key="usuario.id">
            <td>{{ usuario.id }}</td>
            <td>{{ usuario.nombres }}</td>
            <td>{{ usuario.apellidos }}</td>
            <td>{{ usuario.email }}</td>
            <td>{{ usuario.telefono }}</td>
            <td>{{ usuario.direccion }}</td>
            <td>{{ usuario.empresa || 'No tiene' }}</td>
            <td>
              <div class="progress">
                <div class="progress-bar bg-success" role="progressbar" :style="{ width: calcularPorcentajeActividad(usuario) + '%' }" :aria-valuenow="calcularPorcentajeActividad(usuario)" aria-valuemin="0" aria-valuemax="100">
                  {{ calcularPorcentajeActividad(usuario) }}%
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import usuarioService from '@/services/usuario.service.js';
import jsPDF from 'jspdf';
//import html2canvas from 'html2canvas';
import autoTable from 'jspdf-autotable';

export default {
  data() {
    return {
      usuarios: [],
      filtroBusqueda: '',
      ordenAscendente: true,
    };
  },
  computed: {
    usuariosFiltrados() {
      if (!this.filtroBusqueda) return this.usuarios;
      return this.usuarios.filter(usuario => {
        return (
          usuario.nombres.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          usuario.apellidos.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          usuario.email.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          usuario.telefono.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          usuario.direccion.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          (usuario.empresa && usuario.empresa.toLowerCase().includes(this.filtroBusqueda.toLowerCase())) ||
          usuario.id.toString().includes(this.filtroBusqueda)
        );
      });
    }
  },
  mounted() {
    this.obtenerActividadUsuarios();
  },
  methods: {
    async obtenerActividadUsuarios() {
      try {
        const response = await usuarioService.obtenerActividadUsuarios();
        this.usuarios = response.data;
      } catch (error) {
        console.error('Error al obtener usuarios:', error);
      }
    },

    ordenarPorNombres() {
      if (Array.isArray(this.usuarios)) {
        this.usuarios.sort((a, b) => {
          const nombreA = a.nombres.toUpperCase();
          const nombreB = b.nombres.toUpperCase();
          if (nombreA < nombreB) return this.ordenAscendente ? -1 : 1;
          if (nombreA > nombreB) return this.ordenAscendente ? 1 : -1;
          return 0;
        });
        this.ordenAscendente = !this.ordenAscendente;
      } else {
        console.error('La propiedad usuarios no es un array.');
      }
    },

    calcularPorcentajeActividad(usuario) {
      if (!usuario.actividad || !usuario.actividad.inicio_sesion || !usuario.actividad.ultima_actividad) {
        return 0;
      }
      const inicioSesion = new Date(usuario.actividad.inicio_sesion);
      const ultimaActividad = new Date(usuario.actividad.ultima_actividad);
      const duracionSesion = (ultimaActividad - inicioSesion) / 1000; // Duración en segundos
      const duracionMaxima = 3600; // Duración máxima en segundos para 100%
      return Math.min((duracionSesion / duracionMaxima) * 100, 100);
    },

    /*descargarPDF() {
      let element = document.getElementById('tablaUsuarios');
      html2canvas(element).then((canvas) => {
        let imgData = canvas.toDataURL('image/png');
        let doc = new jsPDF('p', 'mm', 'a4');
        let imgHeight = canvas.height * 208 / canvas.width;
        doc.addImage(imgData, 0, 0, 208, imgHeight);
        doc.save('usuarios.pdf');
      });
    } */
    descargarPDF() {
      const doc = new jsPDF('p', 'mm', 'letter'); // Formato carta
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const headerHeight = 30; // Altura de la cabecera
      const footerHeight = 35; // Altura ajustada para el pie de página

      // Cargar imágenes
      const headerImg = require('@/assets/cabecera.jpg');
      const footerImg = require('@/assets/piepagina.jpg');

      const addHeader = (doc) => {
        doc.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight); // Cabecera de borde a borde
      };

      const addFooter = (doc) => {
        doc.addImage(
          footerImg,
          'PNG',
          0,
          pageHeight - footerHeight,
          pageWidth,
          footerHeight // Pie más alto para mayor legibilidad
        );
      };

      // Generar tabla con autoTable
      doc.autoTable({
        html: '#tablaUsuarios',
        startY: headerHeight + 5, // Inicia después de la cabecera
        margin: { top: headerHeight + 5, bottom: footerHeight + 5 },
        didDrawPage: () => {
          addHeader(doc);
          addFooter(doc);
        },
        theme: 'grid',
        headStyles: { fillColor: [0, 123, 255] }, // Azul estilo Bootstrap
      });

      // Guardar PDF
      doc.save('usuarios.pdf');
    },
  }
};
</script>

<style scoped>
.listas-container {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.header-section {
  margin-bottom: 30px;
}

.header-section h1 {
  font-size: 2.5rem;
  color: #007bff;
  font-weight: bold;
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px; /* Asegura separación uniforme */
}

.search-input {
  width: 300px;
  border-radius: 30px;
  padding: 10px 20px;
  border: 1px solid #ced4da;
  margin-right: 10px; /* Espacio entre el input y los botones */
}

.table {
  width: 100%; /* Asegura que la tabla ocupe todo el ancho */
  table-layout: fixed; /* Distribuye el ancho equitativamente */
}

.custom-table th, .custom-table td {
  text-align: center;
  vertical-align: middle;
  word-wrap: break-word; /* Evita desbordamientos de texto */
  overflow: hidden;
  padding: 10px; /* Ajusta para que no se desborde */
  margin: 0;
}

.custom-table thead th {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
}

.custom-table tbody tr {
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table tbody tr:hover {
  background-color: #e8f0ff;
  transform: translateY(-3px);
}

.table-responsive {
  margin-top: 20px;
  overflow-x: auto;
}
.custom-table {
  width: 100%; /* Asegura que la tabla ocupe todo el ancho disponible */
}
.btn {
  min-width: 150px; /* Establecer un ancho mínimo consistente */
  border-radius: 5px;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn i {
  margin-left: 5px; /* Espacio entre texto e icono */
}

.progress {
  height: 20px;
  margin-bottom: 0;
}

.progress-bar {
  line-height: 20px;
}


</style>

//importamos http.js 
import {http} from './http'
/*export function login(){
    fetch("http://127.0.0.1:8000/api/v1/auth/login").then(
        (res) => {
            console.log(res)
        }
    ).catch(
        (error) => {
            console.log(error)
        }
    )
}*/
export const loginConLaravel = function (datos){
    return http().post('/v1/auth/login', datos); 
}
  
    //metodo axios
    /* axios.post("http://127.0.0.1:8000/api/v1/auth/login", datos).then(
        (res) => {
                console.log(res)
        } 
    ).catch (
        (error) => {
            console.log(error)
        }
    )
}
*/

//funcion anonima
export const perfil = function(){
    return http().get('/v1/auth/perfil');
}
//funcion flecha
export const logout = function () {
  return http().post('/v1/auth/logout');
};


export const actualizarPerfil = function(data){
    return http().post("/user/cambiar-perfil", data)
}


export const registro = function (datos) {
  return http().post('/v1/auth/registro', datos);
}


// Nueva función para actualizar el contador
export const actualizarContador = function (productoId, contadorClicks, userClicks) {
  return http().post("/v1/contador", {
    productoId,
    contadorClicks,
    userClicks,
  });
}







export default {
    // Obtener la lista de usuarios
    obtenerUsuarios() {
      return http().get('/v1/usuario');
    },
  
    // Obtener un usuario por ID
    obtenerUsuarioPorId(id) {
      return http().get(`/v1/usuario/${id}`);
    },
  
    // Crear un nuevo usuario
    crearUsuario(usuarioData) {
      return http().post('/v1/usuario', usuarioData);
    },
  
    // Actualizar un usuario por ID
    actualizarUsuario(id, usuarioData) {
      return http().put(`/v1/usuario/${id}`, usuarioData);
    },
  
    // Eliminar un usuario por ID
    eliminarUsuario(id) {
      return http().delete(`/v1/usuario/${id}`);
    },

     // Obtener la lista de usuarios ordenada por nombres
  async obtenerUsuariosOrdenadosPorNombres() {
    try {
      const response = await http().get('/v1/usuario');
      const usuarios = response.data;

      // Ordena la lista de usuarios por nombres de manera ascendente
      usuarios.sort((a, b) => {
        const nombreA = a.nombres.toUpperCase();
        const nombreB = b.nombres.toUpperCase();
        if (nombreA < nombreB) return -1;
        if (nombreA > nombreB) return 1;
        return 0;
      });

      return usuarios;
    } catch (error) {
      throw error;
    }
  },
};

import { http } from './http';

export default {
 
  
  // Obtener comentarios por product_id---minimalista
  obtenerComentariosPorProductoMini(productoMiniId) {
    return http().get(`/v1/comments?producto_mini_id=${productoMiniId}`);
  },
  
 // Obtener comentarios por user_id
 obtenerComentariosPorUsuarioMini(userId) {
    return http().get(`/v1/commentsmini/user/${userId}`);
  }
  
 }

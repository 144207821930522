<template>
    <router-link to="/encargado">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>
    <div class="card" v-if="perfil">
      <h1>Mi Perfil</h1>
      <h5>Nombre: {{ perfil.nombres }}</h5>
      <h5>Apellidos: {{ perfil.apellidos }}</h5>
      <h5>Teléfono: {{ perfil.telefono }}</h5>
      <h5>Dirección: {{ perfil.direccion }}</h5>
      <h5>Empresa: {{ perfil.empresa }}</h5>
      <h5>Apodo: {{ perfil.apodo }}</h5>
      <h5>Correo: {{ perfil.email }}</h5>
      <Divider />
      <Button @click="abrirModalActualizarPerfil" label="Actualizar Perfil" icon="pi pi-check" class="p-button-success" />
  
      <Dialog header="Actualizar Perfil" v-model:visible="mostrarModalActualizarPerfil" style="width: 50vw">
        <div v-if="perfilActualizado" class="p-fluid">
          <div class="p-field">
            <label for="nombres">Nombre</label>
            <InputText id="nombres" v-model="perfilActualizado.nombres" />
          </div>
          <div class="p-field">
            <label for="apellidos">Apellidos</label>
            <InputText id="apellidos" v-model="perfilActualizado.apellidos" />
          </div>
          <div class="p-field">
            <label for="telefono">Teléfono</label>
            <InputText id="telefono" v-model="perfilActualizado.telefono" />
          </div>
          <div class="p-field">
            <label for="direccion">Dirección</label>
            <InputText id="direccion" v-model="perfilActualizado.direccion" />
          </div>
          <div class="p-field">
            <label for="empresa">Empresa</label>
            <InputText id="empresa" v-model="perfilActualizado.empresa" />
          </div>
          <div class="p-field">
            <label for="apodo">Apodo</label>
            <InputText id="apodo" v-model="perfilActualizado.apodo" />
          </div>
          <div class="p-field">
            <label for="email">Correo</label>
            <InputText id="email" v-model="perfilActualizado.email" />
          </div>
        </div>
        <Divider />
        <Button @click="guardarPerfilActualizado" label="Guardar Cambios" icon="pi pi-save" class="p-button-success" />
      </Dialog>
    </div>
  </template>
  
  <script>
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import Divider from 'primevue/divider';
  import Dialog from 'primevue/dialog';
  import FileUpload from 'primevue/fileupload';
  import * as authService from '@/services/auth.service';
  import usuarioService from "@/services/usuario.service";
  
  export default {
    components: {
      InputText,
      Button,
      Divider,
      Dialog,
      FileUpload
    },
    data() {
      return {
        perfil: null,
        imagen_seleccionada: null,
        mostrarModalActualizarPerfil: false,
        perfilActualizado: null
      };
    },
    async mounted() {
      try {
        const response = await authService.perfil();
        if (response.data) {
          this.perfil = response.data;
          this.perfilActualizado = { ...this.perfil };
        } else {
          console.error("No se encontró información de perfil en la respuesta.");
        }
      } catch (error) {
        console.error("Error al obtener el perfil:", error);
      }
    },
    methods: {
      abrirModalActualizarPerfil() {
        this.mostrarModalActualizarPerfil = true;
      },
      async guardarPerfilActualizado() {
        let fd = new FormData();
        fd.append("user_id", this.perfil.id);
        fd.append("imagen", this.imagen_seleccionada);
  
        try {
          await usuarioService.actualizarUsuario(this.perfil.id, this.perfilActualizado);
          await authService.actualizarPerfil(fd);
          this.perfil = { ...this.perfilActualizado };
          this.mostrarModalActualizarPerfil = false;
        } catch (error) {
          console.error("Error al actualizar el perfil:", error);
        }
      }
    }
  };
  </script>
  
  <style scoped>
  .card {
    margin: 20px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    background-color: #fff;
  }
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
  }
  h5 {
    margin: 10px 0;
  }
  .p-button-success {
    margin-top: 20px;
  }
  </style>
  
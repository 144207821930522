<template>
  <div class="container">
    <h1 class="title">Panel Vera Jaus</h1>

    <div class="home">
      <div class="card-container">
        <carta-uno :active-Produ-Tradi="activeProduTradi" @total-productos-tradi="totalProductosTradi" class="card" />
        <carta-dos :active-Produ-Mini="activeProduMini" @total-productos-mini="totalProductosMini" class="card" />
        <carta-tres :active-Produ-Transi="activeProduTransi" @total-productos-trnais="totalProductosTransi" class="card" />
        <carta-cuatro :active-users="activeUsers" @total-usuarios-rol-2="totalUsuariosRol2" class="card" />
      </div>
    </div>

    <!-- Modal de detalles del evento -->
    <div class="modal fade" id="modalDetallesEvento" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDetallesEventoLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="modalDetallesEventoLabel">Detalles del Evento</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p><strong>Título:</strong> {{ eventoActual.title }}</p>
            <p><strong>Descripción:</strong> {{ eventoActual.description }}</p>
            <p><strong>Fecha y Hora:</strong> {{ eventoActual.start }}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
          </div>
        </div>
      </div>
    </div>

       <!-- Contenedor del calendario -->
       <div class="calendar-container">
      <FullCalendar :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import CartaUno from '@/views/admin/CartaUno.vue';
import CartaDos from '@/views/admin/CartaDos.vue';
import CartaTres from '@/views/admin/CartaTres.vue';
import CartaCuatro from '@/views/admin/CartaCuatro.vue';
import eventoService from '@/services/evento.service';

// Importa Bootstrap CSS
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/js/dist/modal';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGrid from '@fullcalendar/timegrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';

export default {
  components: {
    FullCalendar,
    CartaUno,
    CartaDos,
    CartaTres,
    CartaCuatro,
  },
  data() {
    return {
      totalUsuariosRol2: 0,
      totalProductosTradi: 0,
      totalProductosMini: 0,
      totalProductosTransi: 0,
      eventoActual: {
        title: '',
        description: '',
        start: ''
      },
      calendarOptions: {
        plugins: [dayGridPlugin, interactionPlugin, timeGrid, multiMonthPlugin, listPlugin],
        initialView: 'listWeek',
        editable: true,
        selectable: true,
        selectMirror: true,
        dayMaxEvents: true,
        weekends: true,
        allDaySlot: false,
        locale: 'es',
        modalShow: false,
        eventDidMount: this.eventDidMount,
        headerToolbar: {
          left: 'prev,next,today',
          center: 'title',
          right: 'listDay,listWeek,listMonth',
        },
        slotMinTime: '06:00:00',
        slotMaxTime: '24:00:00',
        height: 'auto',
        buttonText: {
          timeGridWeek: 'Semana-Agenda',
          timeGridDay: 'Dia-Agenda',
          today: 'Hoy',
          month: 'Mes',
          week: 'Semana',
          day: 'Día',
          listDay: 'ListaDia',
          listWeek: 'ListaSemana',
          listMonth: 'ListaMes',
          multiMonthYear: 'Año',
        },
        dateClick: this.handleDateClick,
        eventClick: this.handleEventClick,
        events: this.obtenerEventos,
      }
    };
  },
  mounted() {
    this.obtenerEventos();
    import('bootstrap').then((bootstrap) => {
      this.bootstrap = bootstrap;
    });
  },
  methods: {
    actualizarTotalUsuariosRol2(valor) {
      this.totalUsuariosRol2 = valor;
    },
    actualizartotalProductosTradi(valor) {
      this.totalProductosTradi = valor;
    },
    actualizartotalProductosMini(valor) {
      this.totalProductosMini = valor;
    },
    actualizartotalProductosTransi(valor) {
      this.totalProductosTransi = valor;
    },
    async obtenerEventos() {
      try {
        const respuesta = await eventoService.obtenerEventos();
        this.eventos = respuesta.data;
        const eventosVue = this.eventos.map(evento => ({
          title: evento.titulo,
          description: evento.descripcion,
          start: evento.inicio,
          backgroundColor: evento.colorfondo,
          textColor: evento.colortexto,
          date: evento.fecha,
        }));
        this.calendarOptions.events = eventosVue;
      } catch (error) {
        console.error('Error al obtener eventos:', error);
      }
    },
    handleEventClick(info) {
      const clickedEvent = info.event;
      this.eventoActual = {
        title: clickedEvent.title,
        start: clickedEvent.start.toLocaleString(),
        description: clickedEvent.extendedProps.description || 'Sin descripción'
      };
      if (this.bootstrap) {
        const modalElement = document.getElementById('modalDetallesEvento');
        const modal = new this.bootstrap.Modal(modalElement);
        modal.show();
      } else {
        console.error("Bootstrap no está definido todavía. Algo salió mal en la importación.");
      }
    },
  },
};
</script>

<style>
.container {
  padding: 20px;
}

.title {
  text-align: center;
  font-size: 2em;
  margin-bottom: 20px;
  color: #337fff;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 10px; /* Espacio entre las tarjetas y el calendario */
}

.card {
  flex: 1 1 calc(25% - 20px); /* Ajuste de tamaño para mejor alineación */
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 10px;
  padding: 1px; /* Ajustado para mejor visualización */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.modal-header {
  background-color: #337fff;
  color: white;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.modal-footer {
  display: flex;
  justify-content: center;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.btn-primary {
  background-color: #007bff;
  border-color: #007bff;
}

.btn-secondary {
  background-color: #6c757d;
  border-color: #6c757d;
}

.modal-body p {
  margin-bottom: 0.5rem;
}

.modal-body p strong {
  color: #007bff;
}
</style>

<template>
    <div class="surface-0 flex align-items-center justify-content-end min-h-screen min-w-screen overflow-hidden" style="position: relative;">
        <img alt="mueble" src="@/assets/crmimagen.jpg" class="background-image">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:40%; margin-right: 30px; position: relative; z-index: 2;">
            <div class="col-12 mt-5 xl:mt-0 text-center"></div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background-color: rgba(255, 255, 255, 0.253);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:78px; background-color: rgba(255, 255, 255, 0.452);">
                    <div class="text-center mb-2">
                        <img src="@/assets/logoVeraJaus.png" alt="Image" height="50" class="mb-1" style="border-radius:10px" >
                        <div class="text-3xl font-medium mb-2" style="color: #000000;">Bienvenido!</div>
                        <span class="text-600 font-medium">Inicia sesión para continuar</span>
                    </div>
                    <div class="w-full md:w-10 mx-auto">
                        <label for="email1" class="block text-900 text-xl font-medium mb-1">Correo electrónico</label>
                        <InputText id="email1" v-model="usuario.email" type="text" class="w-full mb-2" placeholder="Correo electrónico" style="padding:1rem;" />
                        <label for="password1" class="block text-900 font-medium text-xl mb-1">Contraseña</label>
                        <Password id="password1" v-model="usuario.password" placeholder="Contraseña" :toggleMask="true" class="w-full mb-2" inputClass="w-full" inputStyle="padding:1rem"></Password>
                        <div class="flex align-items-center justify-content-between mb-3">
                            <div class="flex align-items-center">
                                <Checkbox id="rememberme1" v-model="checked" :binary="true" class="mr-2"></Checkbox>
                                <label for="rememberme1">Recordar</label>
                            </div>
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: #000000;">¿Se te olvidó tu contraseña?</a>
                        </div>
                        <Button label="Ingresar" @click="ingresar" class="w-full p-3 text-xl" style="background-color: #007fbab7; border-radius: 30px;"></Button>
                       
                  <Button label="Registrarse" @click="redirigirRegistro" class="w-full p-3 text-xl mt-2" style="border-radius: 30px; background-color: #b0b0b0; border-color: #b0b0b0; color: white; box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);">
                                </Button>

                        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
                    </div>
                </div>
            </div>
        </div>

        <Toast></Toast>
    </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Toast from 'primevue/toast';
import { useToast } from 'primevue/usetoast';
import { Buffer } from 'buffer';
import * as authService from "../../services/auth.service";

export default {
    components: {
        InputText,
        Password,
        Button,
        Checkbox,
        Toast
    },
    data() {
        return {
            usuario: {
                email: "admin@mail.com",
                password: "admin54321"
            },
            checked: false,
            errorMessage: ""
        };
    },
    methods: {
        async ingresar() {
            try {
                const res = await authService.loginConLaravel(this.usuario);
                const role = res.data.role;
                if (role === 'admin') {
                    this.$router.push('/panel');
                } else if (role === 'cliente') {
                    this.$router.push('/catalogo');
                } else if (role === 'encargado') {
                    this.$router.push('/encargado');
                }
                const base64 = Buffer.from(JSON.stringify(res.data)).toString('base64');
                localStorage.setItem("auth", base64);
                this.$store.dispatch('login', res.data);
            } catch (error) {
                this.errorMessage = "Usuario o contraseña incorrectos.";
                console.error(error);
            }
        },
        redirigirRegistro() {
            this.$router.push('/registro');
        }
    },
    mounted() {
        this.$toast = useToast();
    }
}
</script>

<style scoped>
.pi-eye {
    transform: scale(1.6);
    margin-right: 1rem;
}
.pi-eye-slash {
    transform: scale(1.6);
    margin-right: 1rem;
}
.error-message {
    color: red;
    margin-top: 10px;
}
.background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
    opacity: 0.9;
}
.p-field {
    margin-bottom: 1rem;
}
.p-inputtext-lg {
    font-size: 1.2rem;
    padding: 0.75rem;
}
</style>

import axios from "axios"
import { http } from './http';
import io from 'socket.io-client';

const socket = io('http://localhost:3000'); // URL del servidor de Socket.IO

/*export default {
    // Obtener la lista de evento
    obtenerMessage() {
      return http().get('/v1/messages'); 
    },

    enviarMensaje(messageData) {
        return http().post('/v1/messages', messageData);
    },
    
};
      */

export default {
  // Obtener todos los mensajes
  getAllMessages() {
    return http().get('/v1/message');
  },

  
  // Enviar un nuevo mensaje
  sendMessage(messageData) {
    return http().post('/v1/message', messageData);
  },

  getMessagesWithUser(receiver_id) {
    return http().get(`/v1/message/${receiver_id}`); 
  },
  
  getEmisorMessages() {
    return http().get('/v1/message/emisor');
  },

  // Obtener los mensajes por ID de usuario
  getMessagesByUserId(userId) {
    return http().get(`/v1/message/user/${userId}`);
  },

  // Evento para recibir mensajes privados
  /*onPrivateMessage(callback) {
    socket.on('private message', (data) => {
      callback(data);
    });
  },
  
  // Evento para enviar mensajes privados al servidor
  sendPrivateMessage(messageData) {
    socket.emit('private message', messageData);
  },
  
  // Evento para recibir mensajes anteriores
  onPreviousMessages(callback) {
    socket.on('previous messages', (messages) => {
      callback(messages);
    });
  } */
};


/*
import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/auth/LoginView.vue'
import AppLayout from '../layout/AppLayout.vue';

import { Buffer } from "buffer"
import store from '@/store'
//import App from './../App.vue'
//import CarreraView from "../views/admin/carrera/CarreraView.vue"
//import MostrarCarreraView from "../views/admin/carrera/MostrarCarreraView.vue"
//import PersonaView from "../views/admin/persona/PersonaView.vue"
//import GestionView from "../views/admin/gestion/GestionView.vue"
//import AsignacionMateria from "../views/admin/materia/AsignacionMateria.vue"


const routes = [
 {
  path: '/',
  name:AppLayout,
  component: AppLayout,
  children: [
    {
      path: '',
      name: 'home',
      component: HomeView,
      meta: {requireAuth: true}
    },
    {
      path: '/about',
      name: 'about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(// webpackChunkName: "about" // '../views/AboutView.vue')
    },

  ]
 }, */
  /*
    {
      path: '/',
      redirect: 'login'
    },
    {
      path: '/login',
      name: 'Login',
      component:LoginView
    }
  ]
  */
    
 //---------------------------sakai.------






  
//} 
//]
 
 
/*

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// guard
router.beforeEach((to, from, next) => {
  console.log(to)
  if(to.meta.requireAuth){
    if(store.state.auth != null){
      next();
    }

      let auth = localStorage.getItem("auth");
      let authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
      if(authJson.access_token){
        next()
      }
    

    next("login")
  }

  next();

});


export default router;
*/


//----------------------------------------

import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/auth/LoginView.vue'
import store from '@/store'
import { Buffer } from "buffer"
import App from './../App.vue'
//------------------------ADMINISTRADOR---------------------------------------------
import PanelView from '../views/admin/PanelView.vue'
import ChatsView from '../views/admin/ChatsView.vue'
import CalendarioView from '../views/admin/CalendarioView.vue'
import ListasView from '../views/admin/ListasView.vue'
import EstadisticasView from '../views/admin/EstadisticasView.vue'
import EstadisticasPosibleView from '../views/admin/EstadisticasPosibleView'
import EstadisticaRedondoView from '../views/admin/EstadisticaRedondoView'
import EstadisticasClickView from '../views/admin/EstadisticasClickView'
import EstadisticasComentarioView from '../views/admin/EstadisticasComentarioView.vue'
import EstadisticasComentarioMiniView from '.././views/admin/EstadisticasComentarioMiniView.vue'
import EstadisticasComentarioTransiView from '.././views/admin/EstadisticasComentarioTransiView.vue'
import ComentarioMiniView from '.././views/admin/ComentarioMiniView.vue'
import ComentarioTransiView from '.././views/admin/ComentarioTransiView.vue'

import PosiblesClientesView from '../views/admin/PosiblesClientesView.vue'
import ContactosView from '../views/admin/ContactosView.vue'
import CuentasView from '../views/admin/CuentasView.vue'
import TareasView from '../views/admin/TareasView.vue'
import ReunionesView from '../views/admin/ReunionesView.vue'
import InformesView from '../views/admin/InformesView.vue'
import VisitasView from '../views/admin/VisitasView.vue'
import RegistroAdminView from '../views/admin/RegistroAdminView'
import ClientesChatsView from '../views/admin/ClientesChatsView.vue'
//------------------------CATALOGO----------------------------------------
import ProductoMiniView from '../views/admin/ProductoMiniView.vue'
import ProductoTradiView from '../views/admin/ProductoTradiView.vue'
import ProductoTransiView from '../views/admin/ProductoTransiView.vue'

//-------------------------USUARIO----------------------------------------------------

import catalogoView from '../views/cliente/catalogoView'
import RegistroView from '../views/cliente/RegistroView'
import TradicionalView from '../views/cliente/estilosCatalogo/TradicionalView'
import MinimalistaView from '../views/cliente/estilosCatalogo/MinimalistaView'
import TransicionalView from '../views/cliente/estilosCatalogo/TransicionalView'
import ClientesChatsClientesView from '../views/cliente/ClientesChatsClientesView.vue'
import productotransiService from '@/services/productotransi.service'

//------------------------Encargado-------------------------------------------------
import EncargadoView from '../views/encargado/EncargadoView.vue'
import EncargadoCalendarioView  from '../views/encargado/EncargadoCalendarioView.vue'
import EncargadoProductoTradiView from '@/views/encargado/EncargadoProductoTradiView.vue'
import EncargadoProductoMiniView from '@/views/encargado/EncargadoProductoMiniView.vue'
import EncargadoProductoTransiView from '@/views/encargado/EncargadoProductoTransiView.vue'
import EncargadoChatsView from '@/views/encargado/EncargadoChatsView.vue'
import EncargadoPerfilView from '@/views/encargado/EncargadoPerfilView.vue'
import EncargadoEstadisticasClickView from '@/views/encargado/EncargadoEstadisticasClick.vue'
import EncargadoListasView from '@/views/encargado/EncargadoListasView.vue'
import EncargadoClientesChatsView from '@/views/encargado/EncargadoClientesChatsView.vue'

const routes = [
  {
    path: '/admin',
    name: 'app',
    component: App,
    meta: { requireAuth: true }, //-----------
    children: [
      {
        path: '',
        name: 'home',
        component: HomeView,
        meta: {requireAuth: true}
      },
      {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
        meta: {requireAuth: true}
      }, 
       {
        path: '/panel',
        name: 'panel',
        component: PanelView
       },
       {
        path: '/chats',
        name: 'chats',
        component: ChatsView
       },
       {
        path: '/calendario',
        name: 'calendario',
        component: CalendarioView
       },
       {
        path: '/listas',
        name: 'listas',
        component: ListasView
       },
       {
        path: '/estadisticas',
        name: 'estadisticas',
        component: EstadisticasView
       },
       {
        path: '/estadisticasPosible',
        name: 'BarChart',
        component: EstadisticasPosibleView
       },
       {
        path: '/estadisticasClick',
        name: 'click',
        component: EstadisticasClickView
       },
       {
        path: '/estadisticasRedondo',
        name: 'redondo',
        component: EstadisticaRedondoView
       },
       {
        path: '/estadisticasComentario',
        name: 'comentario',
        component: EstadisticasComentarioView
       },
       {
        path: '/estadisticasComentarioMini',
        name: 'comentariomini',
        component: EstadisticasComentarioMiniView
       },
       {
        path: '/estadisticasComentarioTransi',
        name: 'comentariotransi',
        component: EstadisticasComentarioTransiView
       },
       {
        path: '/comentariomini',
        name: 'comentmini',
        component: ComentarioMiniView
       },
       {
        path: '/comentariotransi',
        name: 'comenttransi',
        component: ComentarioTransiView
       },
       
       
       {
        path: '/posiblesclientes',
        name: 'posiblesclientes',
        component: PosiblesClientesView
       },
       {
        path: '/contactos',
        name: 'contactos',
        component: ContactosView
       },
       {
        path: '/cuentas',
        name: 'cuentas',
        component: CuentasView
       },
       {
        path: '/tareas',
        name: 'tareas',
        component: TareasView
       },
       {
        path: '/reuniones',
        name: 'reuniones',
        component: ReunionesView
       },
       {
        path: '/informes',
        name: 'informes',
        component: InformesView
       },
       {
        path: '/visitas',
        name: 'visitas',
        component: VisitasView
       },
       {
        path: '/registroadmin',
        name: 'registroadmin',
        component: RegistroAdminView
      },
      {
        path: '/clienteschats',
        name: 'clienteschats',
        component: ClientesChatsView
      },
      {
        path: '/productotradi',
        name: 'productotradi',
        component: ProductoTradiView
      },
      {
        path: '/productomini',
        name: 'productomini',
        component: ProductoMiniView
      },
      {
        path: '/productotransi',
        name: 'productotransi',
        component: ProductoTransiView
      },
 
    ]
  },
 

  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/catalogo',
    name: 'Catalogo',
    component: catalogoView
  },
  {
    path: '/registro',
    name: 'registro',
    component: RegistroView,
  },
  {
    path: '/tradicional',
    name: 'tradicional',
    component: TradicionalView,
  },
  {
    path: '/minimalista',
    name: 'minimalista',
    component: MinimalistaView,
  },
  {
    path: '/transicional',
    name: 'transicional',
    component: TransicionalView,
  },
  {
    path: '/clienteschatsclientes',
    name: 'clienteschatsclientes',
    component: ClientesChatsClientesView ,
  },
  
  {
    path: '/encargado',
    name: 'encargado',
    component: EncargadoView
  },
  {
    path: '/encargadocalendario',
    name: 'encargadocalendario',
    component: EncargadoCalendarioView
  },
  {
    path: '/encargadotradicional',
    name: 'encargadotradicional',
    component: EncargadoProductoTradiView
  },
  {
    path: '/encargadominimalista',
    name: 'encargadominimalista',
    component: EncargadoProductoMiniView
  },
  {
    path: '/encargadotransicional',
    name: 'encargadotransicional',
    component: EncargadoProductoTransiView
  },
  {
    path: '/encargadochats',
    name: 'encargadochats',
    component: EncargadoChatsView
  },
  {
    path: '/encargadoperfil',
    name: 'encargadoperfil',
    component: EncargadoPerfilView
  },
  {
    path: '/encargadoestadisticasclick',
    name: 'encargadoestadisticasclick',
    component: EncargadoEstadisticasClickView
  },
  {
    path: '/encargadolistas',
    name: 'encargadolistas',
    component: EncargadoListasView
  },
  {
    path: '/encargadoclienteschats',
    name: 'encargadoclienteschats',
    component: EncargadoClientesChatsView
  },
  
  
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// guardia
router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta.requireAuth) {
    let auth = localStorage.getItem("auth");
    if (auth) {
      let authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
      if (authJson && authJson.access_token) {
        next();
      } else {
        next({ path: '/login' });
      }
    } else {
      next({ path: '/login' });
    }
  } else {
    next();
  }
});

/*router.beforeEach((to, from, next) => {
  console.log(to)
  if (to.meta.requireAuth) {
    let auth = localStorage.getItem("auth");
    if (auth) {
      let authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
      if (authJson && authJson.access_token) {
        next();
      } else {
        next({ path: '/login' });
        return;
      }
    } else {
      next({ path: '/login' });
      return;
    }
  }
  next();
}); */


export default router

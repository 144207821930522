<template>
  <div class="container mt-4">
    <h1 class="text-center text-primary mb-4">Todas las Reuniones</h1>
    
    <div class="d-flex justify-content-between align-items-center mb-4">
      <input
        v-model="searchQuery"
        type="text"
        class="form-control"
        placeholder="Buscar por título, descripción, fecha o ID de usuario"
      />
      <button @click="descargarPDF" class="btn btn-primary ml-3">Descargar PDF</button>
    </div>

    <div class="table-responsive">
      <table id="tablaEventos" class="table table-hover table-striped">
        <thead class="table-dark">
          <tr>
            <th>Título</th>
            <th>Descripción</th>
            <th>Fecha y hora</th>
            <th>Fecha</th>
            <th>ID de Usuario</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="evento in filteredEventos" :key="evento.id">
            <td>{{ evento.titulo }}</td>
            <td>{{ evento.descripcion }}</td>
            <td>{{ evento.inicio }}</td>
            <td>{{ evento.fecha }}</td>
            <td>{{ evento.user_id }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import eventoService from '@/services/evento.service.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import headerImg from '@/assets/cabecera.jpg';
import footerImg from '@/assets/piepagina.jpg';

export default {
  data() {
    return {
      eventos: [],
      searchQuery: "", // Variable para el texto de búsqueda
    };
  },
  computed: {
  filteredEventos() {
    return this.eventos.filter((evento) => {
      const search = this.searchQuery.toLowerCase();
      return (
        (evento.titulo && evento.titulo.toLowerCase().includes(search)) ||
        (evento.descripcion && evento.descripcion.toLowerCase().includes(search)) ||
        (evento.inicio && evento.inicio.toLowerCase().includes(search)) ||
        (evento.fecha && evento.fecha.toLowerCase().includes(search)) ||
        (evento.user_id && evento.user_id.toString().includes(search))
      );
    });
  },
},

  mounted() {
    this.obtenerEventos();
  },
  methods: {
    async obtenerEventos() {
      try {
        const response = await eventoService.obtenerEventos();
        this.eventos = response.data;
      } catch (error) {
        console.error('Error al obtener eventos:', error);
      }
    },
    descargarPDF() {
      const doc = new jsPDF('p', 'mm', 'letter'); // Formato carta
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();
      const headerHeight = 30; // Altura de la cabecera
      const footerHeight = 35; // Altura ajustada para el pie de página

      // Función para agregar el encabezado
      const addHeader = (doc) => {
        doc.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);
      };

      // Función para agregar el pie de página
      const addFooter = (doc) => {
        doc.addImage(
          footerImg,
          'PNG',
          0,
          pageHeight - footerHeight,
          pageWidth,
          footerHeight
        );
      };

      // Generar la tabla con los datos de reuniones
      autoTable(doc, {
        head: [['Título', 'Descripción', 'Inicio', 'Fecha', 'ID de Usuario']],
        body: this.filteredEventos.map((evento) => [
          evento.titulo,
          evento.descripcion,
          evento.inicio,
          evento.fecha,
          evento.user_id,
        ]),
        startY: headerHeight + 5, // Inicia después de la cabecera
        margin: { top: headerHeight + 5, bottom: footerHeight + 5 },
        didDrawPage: (data) => {
          addHeader(doc);
          addFooter(doc);
        },
        theme: 'grid',
        headStyles: { fillColor: [0, 123, 255] }, // Azul estilo Bootstrap
      });

      // Guardar el PDF
      doc.save('Listado_Reuniones.pdf');
    },
  },
};
</script>

<style scoped>
.container {
  font-family: Arial, sans-serif;
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #007bff;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table {
  border-radius: 5px;
  overflow: hidden;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.form-control {
  width: 250px;
}
</style>

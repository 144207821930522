// contador.service.js

import { http } from './http';

export default {
 /*  async contadorclic(tipoContador, userId) {
    try {
      const response = await http().post(`/contador/${tipoContador}/${userId}`);
      this.contadortradi = this.contadortradi + 1;
      return response.data;
    } catch (error) {
      console.error('Error al manejar el clic del botón en el servicio contador:', error);
      throw error;
    }
  }, */
  increment: function(type) {
    return http().post(`/v1/contador/${type}`);
},
/*
getContador: function(userId) {
  return http().get(`/v1/contador/${userId}`);
},
*/
getUsers: function() {
  return http().get(`/v1/contador/${users}`);
},

async getUserIds() {
  try {
    const response = await http().get('/v1/contadors/user_ids');
    // Mapea el array de objetos a un array de user_id
    return response.data.map(obj => obj.user_id);
  } catch (error) {
    console.error('Error al obtener los user_id', error);
  }
},
/*
getUserIds() {
  return http().get(`/v1/contadors/user_ids`);
}, */
getContador(userId) {
  return http().get(`/v1/contador/${userId}`);
},
};

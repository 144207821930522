//se va encargar de configurar de manera global mis peticiones al servidor
//se va encargar de configurar todas las petciones http

import axios from "axios"
import { Buffer } from "buffer"

//const urlbase = "http://127.0.0.1:8000/api"  //LA Url de la base de datos en package.json
//const urlbase = process.env.VUE_APP_API_URL;
 //const urlbase = "http://159.223.172.12/api"
 const urlbase = "http://159.223.172.12/api"


//funcion para configurar las peticiones entre cliente y servidor
/*export function http(){
    const interceptor = axios.create({
        baseURL: urlbase,
        Headers: {
            'accept': 'aplication/json',
            'content-Type': 'aplication/json',
            //'Authorization': 'bearer token',
        },
        timeout: 15000
    });
    //captura de errores
    //401, 403
    return interceptor
} */

export function http(){

    let auth = localStorage.getItem("auth");
    let token = ''
    if(auth){
        const authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
        token  = authJson.access_token
    }

    const interceptor = axios.create({
        baseURL: urlbase,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer '+ token,
        },
        timeout: 15000
    });

    // captura de errores
    // 401, 403
    interceptor.interceptors.response.use(
        (response) => {
            return response
        },
        (error) => {
            console.log("******///// ", error)
            if(error.response.status === 401){
                localStorage.removeItem("auth")
                window.location.href = "/login"
            }
        }
    )

    //return {interceptor, token};
    return interceptor;
}
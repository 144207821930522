import { http } from './http';
import { Buffer } from "buffer";

export default {


    obtenerProductos() {
        return http().get('/v1/producto');
    },
   /* crearProducto(producto) {
        return http().post('/v1/producto', producto);
    }, */
    obtenerProducto(id) {
        return http().get(`/v1/producto/${id}`);
    },


 actualizarProducto(id, producto) {
        let formData = new FormData();
        formData.append('nombre', producto.nombre);
        formData.append('descripcion', producto.descripcion);
        formData.append('tipo', producto.tipo);
        if (producto.imagen) {
            formData.append('imagen', producto.imagen, producto.imagen.name);
        }

        return http().put(`/v1/producto/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
    },


    eliminarProducto(id) {
        return http().delete(`/v1/producto/${id}`);
    },

  

      crearProducto(producto) {
        let auth = localStorage.getItem("auth");
        let token = '';
        if(auth){
            const authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
            token  = authJson.access_token;
        }
    
        let config = {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token,
          },
          timeout: 15000
        };
    
        if (producto instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data';
          return http().post('/v1/producto', producto, config);
        } else {
          config.headers['Content-Type'] = 'application/json';
          return http().post('/v1/producto', producto, config);
        }
      },

      
      obtenerTotalProductosTradi() {
    return http().get('/v1/total-productos-tradi');
},

}

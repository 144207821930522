// categoriaCliente.service.js
import { http } from './http';

export default {
  // Crear un nuevo registro en categoria_cliente
  crearCategoriaCliente(data) {
    return http().post('/v1/categoria-cliente', data);
  },

  // Actualizar un registro existente en categoria_cliente
  actualizarCategoriaCliente(id, data) {
    return http().put(`/v1/categoria-cliente/${id}`, data);
  },

   // Eliminar un registro en categoria_cliente
   eliminarCategoriaCliente(id) {
    return http().delete(`/v1/categoria-cliente/${id}`);
  },

  // Obtener todos los registros de categoria_cliente
  obtenerCategoriasClientes() {
    return http().get('/v1/categoria-cliente');
  },

  // Obtener un registro específico de categoria_cliente por su ID
  obtenerCategoriaClientePorId(id) {
    return http().get(`/v1/categoria-cliente/${id}`);
  }
};

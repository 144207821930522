import { http } from './http';
import { Buffer } from "buffer";

export default {


    obtenerProductosmini() {
        return http().get('/v1/producto-mini');
    },
   /* crearProducto(producto) {
        return http().post('/v1/producto', producto);
    }, */
    obtenerProductomini(id) {
        return http().get(`/v1/producto-mini/${id}`);
    },
    actualizarProductomini(id, producto) {
        return http().put(`/v1/producto-mini/${id}`, producto);
    },
    eliminarProductomini(id) {
        return http().delete(`/v1/producto-mini/${id}`);
    },

  

      crearProductomini(producto) {
        let auth = localStorage.getItem("auth");
        let token = '';
        if(auth){
            const authJson = JSON.parse(Buffer.from(auth, 'base64').toString('ascii'));
            token  = authJson.access_token;
        }
    
        let config = {
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer '+ token,
          },
          timeout: 15000
        };
    
        if (producto instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data';
          return http().post('/v1/producto-mini', producto, config);
        } else {
          config.headers['Content-Type'] = 'application/json';
          return http().post('/v1/producto-mini', producto, config);
        }
      },

      
      obtenerTotalProductosMini() {
        return http().get('/v1/total-productos-mini');
    },


}

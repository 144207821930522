<template>
    <div class="estadisticas-mini-container">
      <h1 class="titulo">Estadísticas de Comentarios Mini</h1>
  
      <router-link to="/estadisticas">
        <button type="button" class="btn btn-primary mb-4">
          <i class="bi bi-arrow-left-circle"></i> Regresar
        </button>
      </router-link>
  
      <div class="chart-container" ref="totalSentimientosMiniRef">
      <div class="header-container">
        <h2>Sentimientos Totales de Productos Minimalistas</h2>
        <button type="button" class="btn btn-success" @click="downloadTotalSentimientosMiniPDF">
          <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
        </button>
      </div>
      <Pie v-if="totalSentimientosData && totalSentimientosData.datasets[0].data.length" :data="totalSentimientosData" :options="chartOptions" />
    </div>

    <div class="chart-container" ref="comentariosUsuarioMiniRef">
      <h2>Comentarios por Usuario  Minimalista</h2>
      <div class="form-group mb-4">
        <label for="userSelect" class="form-label">Seleccione un Usuario:</label>
        <select id="userSelect" class="form-control" v-model="selectedUserId" @change="updateComentariosUsuario">
          <option v-for="user in usuarios" :key="user.id" :value="user.id">{{ user.nombreCompleto }}</option>
        </select>
      </div>
      <button type="button" class="btn btn-success mt-2" @click="downloadComentariosUsuarioMiniPDF">
        <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
      </button>
      <Bar v-if="comentariosUsuarioData && comentariosUsuarioData.datasets[0].data.length" :data="comentariosUsuarioData" :options="chartOptions" />
    </div>

    <div class="chart-container" ref="sentimientosProductoMiniRef">
      <h2>Sentimientos por Producto Minimalista</h2>
      <div class="form-group mb-4">
        <label for="productSelect" class="form-label">Seleccione un Producto:</label>
        <select id="productSelect" class="form-control" v-model="selectedProductoMiniId" @change="updateSentimientosProducto">
          <option v-for="product in productos" :key="product.id" :value="product.id">{{ product.id }}. {{ product.nombre }}</option>
        </select>
      </div>
      <button type="button" class="btn btn-success mt-2" @click="downloadSentimientosProductoMiniPDF">
        <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
      </button>
      <Bar v-if="sentimientosProductoData && sentimientosProductoData.datasets[0].data.length" :data="sentimientosProductoData" :options="chartOptions" />
    </div>


    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { Pie, Bar } from 'vue-chartjs';
  import comentarioService from '@/services/comentario.service';
  import usuarioService from '@/services/usuario.service';
  import productoMiniService from '@/services/productomini.service';
  import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'EstadisticasComentarioMiniView',
    components: {
      Pie,
      Bar
    },
    setup() {
    const totalSentimientosMiniRef = ref(null);
    const comentariosUsuarioMiniRef = ref(null);
    const sentimientosProductoMiniRef = ref(null);

      const totalSentimientosData = ref(null);
      const comentariosUsuarioData = ref(null);
      const sentimientosProductoData = ref(null);
      const loaded = ref(false);
  
      const usuarios = ref([]);
      const productos = ref([]);
      const selectedUserId = ref(null);
      const selectedProductoMiniId = ref(null);
  
      const chartOptions = {
        responsive: true,
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: 'rgb(75, 192, 192)',
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(0,0,0,0.7)',
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          },
          y: {
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          }
        }
      };
  
      const procesarDatos = (comentarios) => {
        const totalSentimientos = { positive: 0, negative: 0, neutral: 0 };
  
        comentarios.forEach(comentario => {
          if (comentario.producto_mini_id) {
            totalSentimientos[comentario.sentiment]++;
          }
        });
  
        totalSentimientosData.value = {
          labels: ['Positive', 'Negative', 'Neutral'],
          datasets: [{
            data: [totalSentimientos.positive, totalSentimientos.negative, totalSentimientos.neutral],
            backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
          }]
        };
  
        loaded.value = true;
      };
  
      const obtenerComentarios = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorTipo('mini');
          procesarDatos(response.data);
        } catch (error) {
          console.error("Error al obtener los comentarios:", error);
        }
      };
  
      const updateComentariosUsuario = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorUsuario(selectedUserId.value);
          const comentarios = response.data;
  
          const sentimientos = { positive: 0, negative: 0, neutral: 0 };
          comentarios.forEach(comentario => {
            if (comentario.producto_mini_id) {
              sentimientos[comentario.sentiment]++;
            }
          });
  
          comentariosUsuarioData.value = {
            labels: ['Positive', 'Negative', 'Neutral'],
            datasets: [{
              label: `Usuario ${selectedUserId.value}`,
              data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
              backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
            }]
          };
  
          loaded.value = true;
        } catch (error) {
          console.error("Error al obtener los comentarios por usuario:", error);
        }
      };
  
      const updateSentimientosProducto = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorProductoMini(selectedProductoMiniId.value);
          const comentarios = response.data;
  
          const sentimientos = { positive: 0, negative: 0, neutral: 0 };
          comentarios.forEach(comentario => {
            sentimientos[comentario.sentiment]++;
          });
  
          sentimientosProductoData.value = {
            labels: ['Positive', 'Negative', 'Neutral'],
            datasets: [{
              label: `Producto ${selectedProductoMiniId.value}`,
              data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
              backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
            }]
          };
  
          loaded.value = true;
        } catch (error) {
          console.error("Error al obtener los comentarios por producto:", error);
        }
      };

      const downloadPDF = async (refElement, filename, descripcion) => {
  try {
    const pdf = new jsPDF('p', 'mm', 'letter');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const headerHeight = 30;

    const headerImg = require('@/assets/cabecera.jpg');
    const footerImg = require('@/assets/piepagina.jpg');

    const chartContainer = refElement.value;
    if (!chartContainer) {
      console.error("No se encontró el contenedor del gráfico.");
      return;
    }

    const button = chartContainer.querySelector('button');
    if (button) button.style.display = 'none'; // Ocultar el botón temporalmente

    const canvas = await html2canvas(chartContainer);
    const imgData = canvas.toDataURL('image/png');
    if (button) button.style.display = ''; // Restaurar el botón después de la captura

    pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

    const imgWidth = pageWidth - 40;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

    // Añadir la descripción del gráfico debajo de la imagen
    pdf.setFontSize(12);
    pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);

    pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
    pdf.save(filename);
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};

// Funciones específicas para cada gráfico

const downloadTotalSentimientosMiniPDF = () => {
  const data = totalSentimientosData.value.datasets[0].data;
  const total = data.reduce((a, b) => a + b, 0);

  const descripcion = `
Descripción del gráfico:
- Comentarios positivos: ${data[0]} (${((data[0] / total) * 100).toFixed(2)}%)
- Comentarios negativos: ${data[1]} (${((data[1] / total) * 100).toFixed(2)}%)
- Comentarios neutrales: ${data[2]} (${((data[2] / total) * 100).toFixed(2)}%)
Total de comentarios: ${total}
  `;
  
  downloadPDF(totalSentimientosMiniRef, 'sentimientos_mini.pdf', descripcion);
};

const downloadComentariosUsuarioMiniPDF = () => {
  const data = comentariosUsuarioData.value.datasets[0].data;
  
  const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
  `;

  downloadPDF(comentariosUsuarioMiniRef, 'comentarios_usuario_mini.pdf', descripcion);
};

const downloadSentimientosProductoMiniPDF = () => {
  const data = sentimientosProductoData.value.datasets[0].data;

  const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
  `;

  downloadPDF(sentimientosProductoMiniRef, 'sentimientos_producto_mini.pdf', descripcion);
};

  
      onMounted(async () => {
        await obtenerComentarios();
        usuarios.value = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
        const productosResponse = await productoMiniService.obtenerProductosmini();
        productos.value = productosResponse.data;
      });
  
      return {
        totalSentimientosMiniRef,
        comentariosUsuarioMiniRef,
        sentimientosProductoMiniRef,
        totalSentimientosData,
        comentariosUsuarioData,
        sentimientosProductoData,
        chartOptions,
        loaded,
        usuarios,
        productos,
        selectedUserId,
        selectedProductoMiniId,
        updateComentariosUsuario,
        updateSentimientosProducto,
        downloadTotalSentimientosMiniPDF,
      downloadComentariosUsuarioMiniPDF,
      downloadSentimientosProductoMiniPDF,
      };
    }
  };
  </script>
  
  <style scoped>
  .estadisticas-mini-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .titulo {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .chart-container {
    margin-bottom: 40px;
  }
  
  .chart-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .btn-primary {
    display: flex;
    align-items: center;
  }
  
  .btn-primary i {
    margin-right: 0.5rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  </style>
  
import { http } from './http';

export default {
 
   // Obtener comentarios por product_id
   obtenerComentariosPorProducto(productId) {
    return http().get(`/v1/comments?product_id=${productId}`);
  },
  
 // Obtener comentarios por user_id
 obtenerComentariosPorUsuario(userId) {
    return http().get(`/v1/comments/user/${userId}`);
  }
  
 }

<template>
    <router-link to="/estadisticas"> 
    <button type="button" class="btn btn-primary">Regresar</button>
  </router-link>
    <Doughnut :data="chartData" :options="chartOptions"/>
  </template>
  
  <script>
  import { Doughnut } from 'vue-chartjs'
  import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'
  
  ChartJS.register(ArcElement, Tooltip, Legend)
  
  export default {
    name: 'BarChart',
    components: { Doughnut },
    data() {
      return {
        chartData: {
          labels: [ 'Tradicional', 'Minimalista', 'Transicional'],
          datasets: [
            {
              label: 'Datos',
              backgroundColor: ['#26923E', '#268892', '#9463CF'],
              data: [6, 3, 4]
            }
          ]
        },
        chartOptions: {
        responsive: true,
        
        aspectRatio: 2,
        }
      }
    }
  }
  </script>
<template>
    <router-link to="/encargado">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>
    <div class="container my-5">
      <h2 class="text-center text-primary mb-4">Mensajes Clientes - Encargado</h2>
      <ul class="list-group">
        <li v-for="(message, index) in messages" :key="index" class="list-group-item">
          <p class="mb-1"><strong>De:</strong> {{ message.sender_name }}</p>
          <p class="mb-1">{{ message.message }}</p>
          <p class="mb-1"><strong>Para:</strong> {{ message.receiver_name }}</p>
        </li>
      </ul>
  
      <h2 class="text-center text-primary mt-5 mb-4">Enviar Mensaje</h2>
      <form @submit.prevent="sendMessage">
        <div class="mb-3">
          <label for="receiver_id" class="form-label">ID del destinatario:</label>
          <input type="number" v-model="messageData.receiver_id" class="form-control" id="receiver_id" required>
        </div>
        <div class="mb-3">
          <label for="message" class="form-label">Mensaje:</label>
          <textarea v-model="messageData.message" class="form-control" id="message" rows="3" required></textarea>
        </div>
        <button type="submit" class="btn btn-primary">Enviar</button>
      </form>
    </div>
  </template>
  
  <script>
  import messageService from '@/services/message.service';
  import io from 'socket.io-client';
  
  export default {
    data() {
      return {
        usuarioSeleccionado: null,
        socket: null,
        messages: [],
        messageData: {
          receiver_id: null,
          message: '',
        },
      };
    },
    mounted() {
      this.getAllMessages();
      
      this.socket = io('http://localhost:3000');
  
      this.socket.on('nuevo-mensaje', (mensaje) => {
        this.getAllMessages();
      });
    },
    methods: {
      sendMessage() {
        messageService.sendMessage(this.messageData)
          .then(response => {
            console.log(response.data);
            this.messageData.receiver_id = null;
            this.messageData.message = '';
            this.socket.emit('enviar-mensaje', this.messageData);
          })
          .catch(error => {
            console.error('Error al enviar el mensaje:', error);
          });
      },
      getAllMessages() {
        messageService.getAllMessages()
          .then(response => {
            this.messages = response.data.data;
          })
          .catch(error => {
            console.error('Error al obtener los mensajes:', error);
          });
      },
    },
  };
  </script>
  
  <style scoped>
  .container {
    font-family: Arial, sans-serif;
    background-color: #f8f9fa;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  }
  
  h2 {
    color: #0a529e;
  }
  
  .list-group-item {
    margin-bottom: 10px;
  }
  
  .form-label {
    color: #6c757d;
  }
  
  .form-control {
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  .btn-primary {
    background-color: #1864b4;
    border: none;
  }
  
  .btn-primary:hover {
    background-color: #0056b3;
  }
  </style>
  
<template>
    <div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card p-0 overflow-hidden flex flex-column">
                <div class="flex align-items-center p-3">

                    
                      <i class="fas fa-couch text-6xl text-orange-500"></i> <!-- Sofá para representar "Tradicional" -->
                    <div class="ml-3">
                        <span class="text-orange-500 block white-space-nowrap">
                            TRADICIONAL
                        </span>
                        <span class="text-orange-500 block text-4xl font-bold">
                            {{ totalProductosTradi }}
                        </span>
                    </div>
                </div>
                <img src="@/assets/img/cartas/locations.svg" class="w-full" alt="ubicaciones">
            </div>
        </div>
    </div>
</template>

<script>
import productoService from '@/services/producto.service';
export default {
    props: {
        activeProduTradi: Number
    },
    data() {
        return {
            totalProductosTradi: 0 // Inicializa el total como 0
        };
    },
    mounted() {
        this.obtenerTotalProductosTradi(); // Llama a la función al cargar el componente
    },
    methods: {
        async obtenerTotalProductosTradi() {
  try {
    const response = await productoService.obtenerTotalProductosTradi();
    if (response && response.data && response.data.total_productos_tradi) {
      this.totalProductosTradi = response.data.total_productos_tradi;
    } else {
        console.log('Respuesta completa:', response);

    }
  } catch (error) {
    console.error('Error al obtener el total :', error);
  }
}

    }

};
</script>



<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "@/AppTopbar.vue";
import AppMenu from "@/AppMenu.vue";
import AppConfig from "@/AppConfig.vue";
import AppFooter from "@/AppFooter.vue";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "Encargado de Ventas",
          items: [
            {
              label: "Inicio",
              icon: "pi pi-fw pi-home",
              to: "/encargado",
            },
            {
              label: "Encargado Perfil",
              icon: "pi pi-fw pi-user",
              to: "/encargadoperfil",
            },
          ],
        },
        {
          label: "CRM",
          icon: "pi pi-fw pi-sitemap",
          items: [
          
            {
              label: "Encargado Listas",
              icon: "pi pi-fw pi-users",
              to: "/encargadolistas",
            },
            {
              label: "Encargado Calendario",
              icon: "pi pi-fw pi-calendar",
              to: "/encargadocalendario",
            },
            {
              label: "Encargado Tradicional",
              icon: "pi pi-fw pi-chart-bar",
              to: "/encargadotradicional",
            },
            {
              label: "Encargado Minimalista",
              icon: "pi pi-fw pi-chart-bar",
              to: "/encargadominimalista",
            },
            {
              label: "Encargado Transicional",
              icon: "pi pi-fw pi-chart-bar",
              to: "/encargadotransicional",
            },
            {
              label: "Encargado Estadisticas",
              icon: "pi pi-fw pi-chart-bar",
              to: "/encargadoestadisticasclick",
            },
            {
              label: "Encargado Chats",
              icon: "pi pi-fw pi-comments",
              to: "/encargadochats",
            },
            {
              label: "Chat tiempo Real",
              icon: "pi pi-fw pi-users",
              to: "/encargadoclienteschats",
            },

            
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;
      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }
      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      document.body.classList.add("body-overflow-hidden");
    else document.body.classList.remove("body-overflow-hidden");
  },
  components: {
    AppTopBar,
    AppMenu,
    AppConfig,
    AppFooter,
  },
};
</script>

<style lang="scss">
@import "@/App.scss";

.layout-sidebar {
  width: 250px;
}

.layout-static .layout-main-container {
  margin-left: 250px;
}

@media (max-width: 991px) {
  .layout-sidebar {
    width: 200px;
  }
  .layout-static .layout-main-container {
    margin-left: 200px;
  }
}
</style>

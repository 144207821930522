<template>
	<div class="layout-footer d-flex align-items-center justify-content-between p-3" style="background-color: #343a40; color: white;">
		<div class="d-flex align-items-center">
			
			<span>© Copyright VeraJaus. Todos los derechos reservados.</span>
		</div>
		
	</div>
</template>

<script>
export default {
	name: "AppFooter",
	methods: {
		footerImage() {
			return this.$appState.darkTheme ? 'images/logo-white.svg' : 'images/logo-dark.svg';
		}
	},
	computed: {
		darkTheme() {
			return this.$appState.darkTheme;
		}
	}
}
</script>

<style scoped>
.layout-footer {
	background-color: #343a40;
	color: white;
	border-top: 1px solid #495057;
}
.layout-footer img {
	border-radius: 10px;
}
.layout-footer span {
	color: white;
}
</style>

<template>
  <div>
    
    <header class="header">
      <div class="d-flex align-items-center ms-3">
			<img src="@/assets/logoVeraJaus.png" alt="Image" height="50" class="mb-1 me-2" style="border-radius:10px;">
			<span class="fs-4 fw-bold text-white">VERA JAUS</span>
		</div>
      
      <router-link to="/clienteschatsclientes">
        <Button icon="pi pi-comments" label="Chat" class="p-button-rounded p-button-primary"></Button>
      </router-link>
      <div class="menu">
        <Button icon="pi pi-bars" label="Menú" @click="$refs.menu.toggle($event)"></Button>
        <Menu ref="menu" :model="items" popup />
      </div>
    </header>

    <Dialog header="Mi Perfil" v-model:visible="mostrarPerfil" style="width: 50vw">
      <div v-if="perfil" class="profile-details">
        <div><strong>Nombre:</strong> {{ perfil.nombres }}</div>
        <div><strong>Apellidos:</strong> {{ perfil.apellidos }}</div>
        <div><strong>Teléfono:</strong> {{ perfil.telefono }}</div>
        <div><strong>Dirección:</strong> {{ perfil.direccion }}</div>
        <div><strong>Empresa:</strong> {{ perfil.empresa }}</div>
        <div><strong>Apodo:</strong> {{ perfil.apodo }}</div>
        <div><strong>Correo:</strong> {{ perfil.email }}</div>
      </div>
      <Divider />
      
      <div>
        <Button @click="abrirModalActualizarPerfil" label="Actualizar Perfil" icon="pi pi-check" class="p-button-success"></Button>
      </div>
    </Dialog>

    <Dialog header="Actualizar Perfil" v-model:visible="mostrarModalActualizarPerfil" style="width: 50vw">
      <div v-if="perfilActualizado" class="p-fluid">
         
        <div class="p-field">
          <label for="nombres">Nombre</label>
          <InputText id="nombres" v-model="perfilActualizado.nombres" />
        </div>
        <div class="p-field">
          <label for="apellidos">Apellidos</label>
          <InputText id="apellidos" v-model="perfilActualizado.apellidos" />
        </div>
        <div class="p-field">
          <label for="telefono">Teléfono</label>
          <InputText id="telefono" v-model="perfilActualizado.telefono" />
        </div>
        <div class="p-field">
          <label for="direccion">Dirección</label>
          <InputText id="direccion" v-model="perfilActualizado.direccion" />
        </div>
        <div class="p-field">
          <label for="empresa">Empresa</label>
          <InputText id="empresa" v-model="perfilActualizado.empresa" />
        </div>
        <div class="p-field">
          <label for="apodo">Apodo</label>
          <InputText id="apodo" v-model="perfilActualizado.apodo" />
        </div>
        <div class="p-field">
          <label for="email">Correo</label>
          <InputText id="email" v-model="perfilActualizado.email" />
        </div>
        <div class="p-field">
          <label for="nuevaContrasena">Nueva Contraseña</label>
          <InputText id="nuevaContrasena" v-model="nuevaContrasena" type="password" />
        </div>
      </div>
      <Divider />
      <Button @click="guardarPerfilActualizado" label="Guardar Cambios" icon="pi pi-save" class="p-button-success" />
    </Dialog>
 <!-- Modal de éxito para la actualización del perfil -->
 <div v-if="mostrarModalExito" class="modal-overlay">
      <div class="modal-content animate-fade">
        <i class="pi pi-check-circle modal-icon"></i>
        <h3 class="modal-title">Actualización de perfil exitosa</h3>
      </div>
    </div>
    <div class="mi-contenedor p-grid">
      <div class="p-col-12 p-md-4" v-for="categoria in categorias" :key="categoria.id">
        <Card>
          <template #header>
            <img :src="categoria.imagen" alt="Imagen del producto" class="img" />
          </template>
          <template #content>
            <h3>{{ categoria.nombre }}</h3>
            <p>{{ categoria.descripcion }}</p>
            <router-link :to="categoria.ruta">
              <Button label="Ver Catálogo" class="p-button-primary" @click="increment(categoria.tipo)"></Button>
            </router-link>
          </template>
        </Card>
      </div>
    </div>

    <footer class="footer">
      <span>© Copyright VeraJaus. Todos los derechos reservados.</span>
    </footer>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Card from 'primevue/card';
import Divider from 'primevue/divider';
import FileUpload from 'primevue/fileupload';
import Menu from 'primevue/menu';
import Dialog from 'primevue/dialog';
import usuarioService from "@/services/usuario.service";
import contadorService from '@/services/contador.service';
import { perfil, logout } from '@/services/auth.service';

export default {
  components: {
    InputText,
    Button,
    Card,
    Divider,
    FileUpload,
    Menu,
    Dialog
  },
  data() {
    return {
      perfil: null,
      imagen_seleccionada: null,
      mostrarPerfil: false,
      mostrarModalActualizarPerfil: false,
      mostrarModalExito: false, // Nuevo estado para el modal 
      perfilActualizado: null,
      nuevaContrasena: '',
      categorias: [
        {
          id: 1,
          nombre: 'Muebles Tradicionales',
          descripcion: 'Detalles distinguidos, líneas curvas y diseños simétricos.',
          imagen: require('@/assets/img/categorias/tradi1.jpg'),
          ruta: '/tradicional',
          tipo: 'tradicional',
        },
        {
          id: 2,
          nombre: 'Muebles Minimalistas',
          descripcion: 'Extrema simplicidad, líneas puras y colores neutros.',
          imagen: require('@/assets/img/categorias/minima1.jpg'),
          ruta: '/minimalista',
          tipo: 'minimalista',
        },
        {
          id: 3,
          nombre: 'Muebles Transicionales',
          descripcion: 'Mezcla del estilo tradicional y minimalista.',
          imagen: require('@/assets/img/categorias/transi1.jpg'),
          ruta: '/transicional',
          tipo: 'transicional',
        },
      ],
      items: [
        {
          label: 'Perfil',
          icon: 'pi pi-user',
          command: () => { this.mostrarPerfil = true; }
        },
        {
          label: 'Cerrar Sesión',
          icon: 'pi pi-sign-out',
          command: () => { this.logout(); }
        }
      ]
    };
  },
  async mounted() {
    try {
      const response = await perfil();
      console.log('Perfil response:', response);
      if (response && response.data) {
        this.perfil = response.data;
        this.perfilActualizado = { ...this.perfil }; // Copiamos los datos del perfil para editarlos
      } else {
        console.error("No se encontró información de perfil en la respuesta.");
      }
    } catch (error) {
      console.error("Error al obtener el perfil:", error);
    }
  },
  methods: {
    increment(type) {
      contadorService
        .increment(type)
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    logout() {
      logout()
        .then(() => {
          this.$router.push('/login');
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onImagenSeleccionada(event) {
      const archivo = event.files[0];
      this.imagen_seleccionada = archivo;
      if (archivo) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.vistaPrevia = e.target.result;
        };
        reader.readAsDataURL(archivo);
      }
    },
    abrirModalActualizarPerfil() {
      this.mostrarModalActualizarPerfil = true;
    },
    async guardarPerfilActualizado() {
      try {
        // Actualiza el perfil
        await usuarioService.actualizarUsuario(this.perfil.id, this.perfilActualizado);
        // Si hay una nueva contraseña, se actualiza
        if (this.nuevaContrasena) {
          await usuarioService.cambiarContrasena({ password: this.nuevaContrasena });
        }
        // Actualiza el perfil en la vista
        this.perfil = { ...this.perfilActualizado };
        this.mostrarModalActualizarPerfil = false;
        this.mostrarPerfil = false;

        // Mostramos el modal de éxito
        this.mostrarModalExito = true;
        setTimeout(() => {
          this.mostrarModalExito = false; // Ocultamos el modal después de 2 segundos
        }, 2000);

      } catch (error) {
        console.error("Error al actualizar el perfil:", error);
      }
    }
  }
};
</script>

<style scoped>
/* Contenedor principal para las tarjetas de productos */
.mi-contenedor {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px; /* Separación uniforme entre las tarjetas */
  padding: 20px;
  background-color: #f8f9fa; /* Fondo más claro */
  min-height: 80vh; /* Altura mínima para ajustar el pie de página al final */
  margin-top: 35px;
  box-sizing: border-box;
}

/* Estilo individual para cada tarjeta de producto */
.producto {
  flex: 1 1 280px; /* Ancho mínimo de 280px */
  max-width: 350px; /* Ancho máximo de 350px */
  background-color: #ffffff;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 15px; /* Bordes más redondeados */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra suave */
  padding: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.producto:hover {
  transform: translateY(-5px); /* Efecto de elevación */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Sombra en hover */
}

.img {
  max-width: 100%;
  height: auto;
  border-radius: 10px; /* Borde redondeado para las imágenes */
  margin-bottom: 15px; /* Espacio entre la imagen y el contenido */
}

/* Encabezado */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #333;
  color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

.empresa {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}

.menu {
  display: flex;
  align-items: center;
}

.busqueda input {
  padding: 5px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

/* Pie de página */
.footer {
  background-color: #333;
  color: #fff;
  padding: 15px;
  text-align: center;
  width: 100%;
  font-size: 0.9rem;
  position: relative; /* Ajuste para mantener el pie de página en la parte inferior */
  bottom: 0;
}

.profile-card {
  margin: 20px;
}

.profile-details {
  line-height: 2;
}

/* Botón primario */
.btn-primary {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-primary:hover {
  background-color: #0056b3;
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    text-align: center;
  }

  .menu {
    margin-top: 10px;
  }

  .mi-contenedor {
    padding: 10px;
    gap: 15px; /* Reducir espacio entre tarjetas en pantallas más pequeñas */
  }

  .producto {
    flex: 1 1 100%; /* Tarjetas de ancho completo en pantallas pequeñas */
    max-width: 100%;
  }
}

@media (max-width: 576px) {
  .header .empresa {
    font-size: 1.25rem; /* Reducción del tamaño de la empresa */
  }

  .btn-primary {
    padding: 8px 16px; /* Reducir el tamaño del botón en pantallas pequeñas */
  }
}



.btn-success {
  background-color: #28a745;
  border: none;
  padding: 10px 20px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}
.btn-success:hover {
  background-color: #218838;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-content {
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeInOut 1s ease-in-out;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4CAF50;
  margin: 0;
}
.modal-icon {
  font-size: 3rem;
  color: #4CAF50;
  margin-bottom: 0.5rem;
}
@keyframes fadeInOut {
  0% { opacity: 0; transform: scale(0.9); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0.9); }
}
</style>

<template>
    <div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card p-0 overflow-hidden flex flex-column">
                <div class="flex align-items-center p-3">
                    <i class="fas fa-chair text-6xl text-green-500"></i>
                    <div class="ml-3">
                        <span class="text-green-500 block white-space-nowrap">
                            TRANSICIONAL
                        </span>
                        <span class="text-green-500 block text-4xl font-bold">
                            {{ totalProductosTransi }}
                        </span>
                    </div>
                </div>
                <img src="@/assets/img/cartas/rate.svg" class="w-full" alt="conversión">
            </div>
        </div>
    </div>
</template>

<script>
import productotransiService from '@/services/productotransi.service';
export default {
    props: {
        activeProduTransi: Number
    },
    data() {
        return {
            totalProductosTransi: 0 // Inicializa el total como 0
        };
    },
    mounted() {
        this.obtenerTotalProductosTransi(); // Llama a la función al cargar el componente
    },
    methods: {
        async obtenerTotalProductosTransi() {
  try {
    const response = await productotransiService.obtenerTotalProductosTransi();
    if (response && response.data && response.data.total_productos_transi) {
      this.totalProductosTransi = response.data.total_productos_transi;
    } else {
        console.log('Respuesta completa:', response);

    }
  } catch (error) {
    console.error('Error al obtener el total :', error);
  }
}

    }
};
</script>

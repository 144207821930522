
// ajustar la ruta según tu estructura de carpetas
import axios from "axios"
import { http } from './http';

export default {
    // Obtener la lista de evento
    obtenerEventos() {
      return http().get('/v1/obtener-eventos'); 
    },
      
    
  
    // Obtener un evento por ID
    obtenerEventoPorId(id) {
      return http().get(`/v1/evento/${id}`);
    },
  
    // Crear un nuevo evento
   // Cambia el método en evento.service.js
    crearNuevoEvento(eventoData) {
    return http().post('/v1/evento', eventoData);
    },


  
    // Actualizar un usuario por ID
    actualizarEvento(id, eventoData) {
      return http().put(`/v1/evento/${id}`, eventoData);
    },
  
    // Eliminar un usuario por ID
    eliminarEvento(id) {
      return http().delete(`/v1/evento/${id}`);
    },

    async obtenerEventosPorUsuario(id) {
      try {
        const response = await http.get(`/v1/usuario/${id}/eventos`);
        return response.data;
      } catch (error) {
        console.error('Error al obtener eventos:', error);
      }
    },
}

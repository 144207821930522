<template>
    <div class="producto-container">
      <h1 class="titulo">Gestión de Productos Minimalistas - Encargado</h1>
      <div class="top-buttons">
        <router-link to="/encargado">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>
        <Button label="Añadir Producto" icon="pi pi-plus" class="p-button-success" @click="mostrarModalCrear = true"></Button>
      </div>
  
      <div class="lista-productos">
        <div class="mb-2">
          <InputText v-model="filtroBusqueda" placeholder="Buscar productos por ID, nombre, descripción o tipo" class="input-busqueda" />
        </div>
        <DataTable :value="productosFiltrados" class="p-datatable-gridlines">
          <Column field="id" header="ID"></Column>
          <Column field="nombre" header="Nombre"></Column>
          <Column field="descripcion" header="Descripción"></Column>
          <Column field="tipo" header="Tipo"></Column>
          <Column header="Imagen">
            <template #body="slotProps">
              <img :src="`http://127.0.0.1:8000/images/minimalista/${slotProps.data.imagen}`" alt="Imagen del producto" class="img-producto" />
            </template>
          </Column>
          <Column header="Acciones">
            <template #body="slotProps">
              <div class="botones-acciones">
                
                <Button label="Comentarios" class="p-button-info" @click="mostrarModalComentarios(slotProps.data.id)" />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
  
      <!-- Modal Crear -->
      <Dialog header="Añadir Producto" v-model:visible="mostrarModalCrear" modal>
        <div class="formulario-producto">
          <InputText v-model="producto.nombre" placeholder="Nombre del producto" class="input-producto" />
          <InputText v-model="producto.descripcion" placeholder="Descripción del producto" class="input-producto" />
          <InputText v-model="producto.tipo" placeholder="Tipo del producto" class="input-producto" />
          <input type="file" @change="onFileChange" class="input-file" />
          <img v-if="producto.imagen" :src="imagenPrevia" alt="Imagen del producto" class="img-producto-preview" />
          <Button label="Crear Producto" icon="pi pi-check" class="p-button-success" @click="crearProductomini" />
        </div>
      </Dialog>
  
      <!-- Modal Comentarios -->
      <Dialog header="Comentarios" v-model:visible="mostrarModalComentarioVisible" modal>
        <div class="formulario-comentarios">
          <div v-if="comentarios.length">
            <h3>Comentarios</h3>
            <ul class="comentarios-list">
              <li v-for="comentario in comentarios" :key="comentario.id">
                <strong>{{ comentario.user.nombres }}:</strong> {{ comentario.comment }}
              </li>
            </ul>
          </div>
          <div v-else>
            <p>No hay comentarios para este producto.</p>
          </div>
        </div>
      </Dialog>
  
      <!-- Modal Confirmar Eliminación -->
      <Dialog header="Confirmar Eliminación" v-model:visible="mostrarModalConfirmacion" modal>
        <p>¿Seguro que quiere eliminarlo?</p>
        <div class="botones-confirmacion">
          <Button label="Sí" class="p-button-danger" @click="eliminarProductoConfirmado" />
          <Button label="No" class="p-button-secondary" @click="mostrarModalConfirmacion = false" />
        </div>
      </Dialog>
    </div>
  </template>
  
  <script>
  import { ref, computed, onMounted } from 'vue';
  import ProductominiService from '@/services/productomini.service';
  import comentarioService from '@/services/comentario.service';
  import InputText from 'primevue/inputtext';
  import Button from 'primevue/button';
  import DataTable from 'primevue/datatable';
  import Column from 'primevue/column';
  import Dialog from 'primevue/dialog';
  
  export default {
    components: {
      InputText,
      Button,
      DataTable,
      Column,
      Dialog
    },
    setup() {
      const mostrarModalCrear = ref(false);
      const mostrarModalComentarioVisible = ref(false);
      const mostrarModalConfirmacion = ref(false);
      const filtroBusqueda = ref('');
      const producto = ref({
        nombre: '',
        descripcion: '',
        tipo: 'minimalista', // Tipo predeterminado
        imagen: null
      });
      const productos = ref([]);
      const comentarios = ref([]);
      const productoIdSeleccionado = ref(null);
      const productoIdAEliminar = ref(null);
  
      const imagenPrevia = computed(() => {
        return producto.value.imagen ? URL.createObjectURL(producto.value.imagen) : '';
      });
  
      const productosFiltrados = computed(() => {
        if (!filtroBusqueda.value) return productos.value;
        const filtro = filtroBusqueda.value.toLowerCase();
        return productos.value.filter(p => {
          return (
            p.id.toString().includes(filtro) ||
            p.nombre.toLowerCase().includes(filtro) ||
            p.descripcion.toLowerCase().includes(filtro) ||
            p.tipo.toLowerCase().includes(filtro)
          );
        });
      });
  
      const obtenerProductosmini = () => {
        ProductominiService.obtenerProductosmini()
          .then(response => {
            productos.value = response.data;
          })
          .catch(error => {
            console.error('Hubo un error al obtener los productos:', error);
          });
      };
  
      const crearProductomini = async () => {
        let formData = new FormData();
        formData.append('nombre', producto.value.nombre);
        formData.append('descripcion', producto.value.descripcion);
        formData.append('tipo', producto.value.tipo); // Tipo del producto
        if (producto.value.imagen) {
          formData.append('imagen', producto.value.imagen, producto.value.imagen.name);
        }
  
        try {
          let response = await ProductominiService.crearProductomini(formData);
          if (response.data && response.data.id) {
            productos.value.push(response.data);
            producto.value = { nombre: '', descripcion: '', tipo: 'minimalista', imagen: null }; // Restablecer valor predeterminado
            mostrarModalCrear.value = false;
          }
        } catch (error) {
          console.error(error);
        }
      };
  
      const onFileChange = e => {
        producto.value.imagen = e.target.files[0];
      };
  
      const mostrarModalComentarios = async id => {
        productoIdSeleccionado.value = id;
        mostrarModalComentarioVisible.value = true;
        try {
          const response = await comentarioService.obtenerComentariosPorProductoMini(id);
          comentarios.value = response.data;
        } catch (error) {
          console.error("Error al obtener los comentarios:", error);
        }
      };
  
      onMounted(() => {
        obtenerProductosmini();
      });
  
      return {
        mostrarModalCrear,
        filtroBusqueda,
        producto,
        productosFiltrados,
        imagenPrevia,
        crearProductomini,
        
        onFileChange,
        mostrarModalComentarios,
        mostrarModalComentarioVisible,
        mostrarModalConfirmacion,
        comentarios
      };
    }
  };
  </script>
  
  <style scoped>
  .producto-container {
    padding: 20px;
    max-width: 1000px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .titulo {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .top-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .p-button-success {
    margin-bottom: 20px;
  }
  
  .lista-productos {
    margin-top: 20px;
  }
  
  .p-datatable-gridlines {
    border: 1px solid #dee2e6;
  }
  
  .input-busqueda {
    margin-bottom: 10px;
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .formulario-producto {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }
  
  .input-producto,
  .input-file {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    font-family: 'Arial', sans-serif;
  }
  
  .img-producto,
  .img-producto-preview {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 4px;
    margin-top: 10px;
  }
  
  .botones-acciones {
    display: flex;
    gap: 10px;
  }
  
  .formulario-comentarios {
    margin-top: 20px;
  }
  .comentarios-list {
    list-style-type: none;
    padding: 0;
  }
  .comentarios-list li {
    margin-bottom: 10px;
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 5px;
  }
  .botones-confirmacion {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  .p-button {
    min-width: 90px; /* Asegura consistencia en el tamaño */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  </style>
  
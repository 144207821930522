<template>
  <div class="home">
    <div class="welcome-section">
      <h1 class="title">Bienvenido</h1>
      <p class="description">Explora los distintos catálogos de muebles disponibles para administrar y personalizar la experiencia de tus clientes.</p>
      
      <div class="catalog-cards">
        <!-- Catálogo Tradicional -->
        <div class="catalog-card card-traditional">
          <router-link to="/productotradi" class="card-content">
            <i class="bi bi-archive icon"></i>
            <h2>Catálogo Tradicional</h2>
            <p class="card-description">Muebles clásicos y elegantes, perfectos para ambientes tradicionales.</p>
            <button type="button" class="btn btn-primary">Explorar</button>
          </router-link>
        </div>

        <!-- Catálogo Minimalista -->
        <div class="catalog-card card-minimalist">
          <router-link to="/productomini" class="card-content">
            <i class="bi bi-house icon"></i>
            <h2>Catálogo Minimalista</h2>
            <p class="card-description">Diseños simples y funcionales para espacios modernos y despejados.</p>
            <button type="button" class="btn btn-secondary">Explorar</button>
          </router-link>
        </div>

        <!-- Catálogo Transicional -->
        <div class="catalog-card card-transitional">
          <router-link to="/productotransi" class="card-content">
            <i class="bi bi-grid-3x3 icon"></i>
            <h2>Catálogo Transicional</h2>
            <p class="card-description">Una fusión de lo clásico y moderno para una estética equilibrada.</p>
            <button type="button" class="btn btn-success">Explorar</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCatalogButtons",
};
</script>

<style scoped>
.home {
  padding-top: 20px; /* Reducido para acercar el contenido a la parte superior */
  background: linear-gradient(to right, #f0f4f8a4, #d9e2ec);
  min-height: 100vh;
  display: flex;
  align-items: flex-start; /* Alineación al inicio para que esté más arriba */
  justify-content: center;
}

.welcome-section {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 1000px;
  width: 100%;
  margin-top: 0; /* Eliminado para que esté lo más arriba posible */
}

.title {
  font-size: 2.5rem;
  color: #343a40;
  margin-bottom: 10px;
}

.description {
  color: #6c757d;
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.catalog-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); /* Ajuste responsivo */
  gap: 20px;
}

.catalog-card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.catalog-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.icon {
  font-size: 3rem;
  margin-bottom: 15px;
}

.card-content {
  text-decoration: none;
  color: inherit;
}

h2 {
  font-size: 1.5rem;
  color: #343a40;
  margin-bottom: 10px;
}

.card-description {
  font-size: 1rem;
  color: #6c757d;
  margin-bottom: 15px;
}

/* Estilos personalizados para cada tarjeta */
.card-traditional {
  border-left: 5px solid #007bff;
}

.card-minimalist {
  border-left: 5px solid #6c757d;
}

.card-transitional {
  border-left: 5px solid #28a745;
}

.btn {
  padding: 8px 20px;
  font-size: 1rem;
  font-weight: bold;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  border: none;
}

.btn-primary:hover {
  background-color: #0056b3;
}

.btn-secondary {
  background-color: #6c757d;
  border: none;
}

.btn-secondary:hover {
  background-color: #5a6268;
}

.btn-success {
  background-color: #28a745;
  border: none;
}

.btn-success:hover {
  background-color: #218838;
}
</style>


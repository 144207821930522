<template>
  <div>
    <header class="header">
      <div class="d-flex align-items-center ms-3">
        <img src="@/assets/logoVeraJaus.png" alt="Image" height="50" class="logo-img me-2">
        <span class="fs-4 fw-bold text-white">VERA JAUS</span>
      </div>
      <div class="busqueda">
        <InputText placeholder="Buscar productos" v-model="filtroBusqueda" @input="buscarProductos" class="input-busqueda" />
        <Button icon="pi pi-search" class="p-button-rounded p-button-info btn-buscar" />
      </div>
      <div class="menu">
        <Button icon="pi pi-bars" class="menu-button p-button-rounded p-button-secondary" @click="$refs.menu.toggle($event)" />
        <Menu ref="menu" :model="items" popup />
      </div>
    </header>

    <br>
    <router-link to="/catalogo">
      <Button icon="pi pi-arrow-left" label="Regresar" class="p-button-secondary mb-4 btn-regresar"></Button>
    </router-link>

    <div class="hstack">
      <div class="contenido">
        <div class="mostrador" :style="{ width: mostradorWidth }">
          <div class="fila">
            <div class="item" v-for="producto in productosFiltrados" :key="producto.id" @click="cargar(producto)">
              <div class="contenedor-foto">
                <img :src="`http://127.0.0.1:8000/images/tradicional/${producto.imagen}`" alt="Imagen del producto">
              </div>
              <p class="descripcion">{{ producto.nombre }}</p>
              <span class="precio">{{ producto.precio }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="seleccion" :style="{ width: seleccionWidth, opacity: seleccionOpacity }">
        <div class="header">
          <div class="size">
            <label for="tipo">Tipo: </label>
            <select name="tipo" id="tipo" class="select-tipo">
              <option :value="selectedProducto.tipo">{{ selectedProducto.tipo }}</option>
            </select>
          </div>
          <h1 class="salir-imagen" @click="cerrar">Salir</h1>
        </div>
        <div class="info">
          <img :src="selectedProducto.imagenUrl" alt="Imagen del producto" class="img-info">
          <h2 id="modelo">{{ selectedProducto.nombre }}</h2>
          <p id="descripcion">{{ selectedProducto.descripcion }}</p>
          <span class="precio" id="precio">{{ selectedProducto.precio }}</span>
          <div class="fila">
           
            <Button label="Comentar" icon="pi pi-comment" @click="mostrarModalComentario(selectedProducto.id)" class="p-button-info p-button-rounded p-button-raised" />
            <a :href="whatsAppLink" target="_blank" class="whatsapp-link">
              <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp" class="whatsapp-logo">
            </a>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal Comentar -->
    <Dialog header="Comentarios" v-model:visible="mostrarModalComentarioVisible" modal>
      <div class="formulario-comentarios">
        <div v-if="comentarios.length">
          <h3>Comentarios</h3>
          <ul class="comentarios-list">
            <li v-for="comentario in comentarios" :key="comentario.id">
              <strong>{{ comentario.user.nombres }}:</strong> {{ comentario.comment }}
            </li>
          </ul>
        </div>
        <form @submit.prevent="submitComment" class="comentario-form">
          <textarea v-model="comment" placeholder="Escribe tu comentario" class="textarea-comentario"></textarea>
          <Button label="Enviar" type="submit" class="p-button-success p-button-rounded btn-enviar" />
        </form>
      </div>
    </Dialog>
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import Dialog from 'primevue/dialog';
import ProductoService from '@/services/producto.service';
import comentarioService from '@/services/comentario.service';

export default {
  components: {
    InputText,
    Button,
    Menu,
    Dialog
  },
  data() {
    return {
      filtroBusqueda: '',
      productos: [],
      mostradorWidth: "100%",
      seleccionWidth: "0%",
      seleccionOpacity: "0",
      selectedProducto: {},
      items: [
        {
          label: 'Perfil',
          icon: 'pi pi-user',
          command: () => { this.mostrarPerfil = true; }
        },
        {
          label: 'Cerrar Sesión',
          icon: 'pi pi-sign-out',
          command: () => { this.logout(); }
        }
      ],
      mostrarModalComentarioVisible: false,
      comment: '',
      userId: 1, // Obtén este valor según el usuario actual
      productId: null, // ID del producto para el comentario actual
      comentarios: [],
      errorMessage: ''
    };
  },
  computed: {
    productosFiltrados() {
      if (!this.filtroBusqueda) return this.productos;
      const filtro = this.filtroBusqueda.toLowerCase();
      return this.productos.filter(producto => {
        return (
          producto.id.toString().includes(filtro) ||
          producto.nombre.toLowerCase().includes(filtro) ||
          producto.descripcion.toLowerCase().includes(filtro) ||
          producto.tipo.toLowerCase().includes(filtro)
        );
      });
    },
    whatsAppLink() {
      return `https://wa.me/69834679?text=Hola,%20estoy%20interesado%20en%20el%20producto%20${this.selectedProducto.nombre}`;
    }
  },
  created() {
    this.obtenerProductos();
  },
  methods: {
    obtenerProductos() {
      ProductoService.obtenerProductos()
        .then(response => {
          this.productos = response.data;
        })
        .catch(error => {
          console.error('Hubo un error al obtener los productos:', error);
        });
    },
    cargar(producto) {
      this.mostradorWidth = "90%";
      this.seleccionWidth = "60%";
      this.seleccionOpacity = "1";
      this.selectedProducto = {
        ...producto,
        imagenUrl: `http://127.0.0.1:8000/images/tradicional/${producto.imagen}`
      };
    },
    cerrar() {
      this.mostradorWidth = "100%";
      this.seleccionWidth = "0%";
      this.seleccionOpacity = "0";
    },
    agregarAlCarrito() {
      // Implementar la lógica de agregar al carrito aquí
    },
    buscarProductos() {
      // Este método se ejecutará cuando se escriba en el campo de búsqueda
      // Ya está cubierto por la propiedad computada 'productosFiltrados'
    },
    logout() {
      // Implementar la lógica de cierre de sesión aquí
    },
    async mostrarModalComentario(id) {
      this.productId = id;
      this.mostrarModalComentarioVisible = true;
      try {
        const response = await comentarioService.obtenerComentariosPorProducto(id);
        this.comentarios = response.data;
      } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    },
    async submitComment() {
      try {
        const response = await comentarioService.crearComentario({
          comment: this.comment,
          user_id: this.userId,
          product_id: this.productId
        });
        console.log(response.data);
        this.mostrarModalComentarioVisible = false;
        this.comment = '';
        // Recargar los comentarios después de agregar uno nuevo
        await this.mostrarModalComentario(this.productId);
      } catch (error) {
        this.errorMessage = "Error al enviar el comentario: " + (error.response ? error.response.data.error : "No hay respuesta del servidor");
        console.error("Error al enviar el comentario:", error);
        console.error("Error detallado:", error.response ? error.response.data : error.message);
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;800&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Open Sans', sans-serif;
}
body {
  background-color: #f0f2f5;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background-color: #333;
  color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.header .empresa {
  font-size: 24px;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.header .logo-img {
  border-radius: 10px;
}
.header .busqueda {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.input-busqueda {
  padding: 5px;
  margin-right: 5px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 300px;
  background-color: #fff;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.btn-buscar {
  border-radius: 50%;
  margin-left: 10px;
}
.header .menu {
  display: flex;
  align-items: center;
}
.menu-button {
  margin-left: 10px;
}

.contenido {
  max-width: 1200px;
  width: 100%;
  margin: 40px auto;
  display: flex;
}
.mostrador {
  width: 68%;
  transition: .5s ease;
}
.mostrador .fila {
  flex-wrap: wrap;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.mostrador .fila .item {
  width: calc(20% - 20px);
  max-width: 210px;
  padding: 20px;
  margin: 10px;
  height: 350px;
  text-align: center;
  cursor: pointer;
  border-radius: 10px;
  transition: .3s;
  background-color: #17274633;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.mostrador .fila .item:hover {
  background-color: #f1f1f1;
}
.mostrador .fila .item img {
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
}
.mostrador .fila .item .descripcion {
  color: #707070;
  font-weight: bold;
}
.mostrador .fila .item .precio {
  color: #f85151;
  font-weight: bold;
  font-size: 20px;
}
.seleccion {
  transition: .5s ease;
  opacity: 0;
  width: 0%;
  border: 1px solid #ccc;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
}
.seleccion .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  margin-bottom: 10px;
}
.seleccion .header .size {
  display: flex;
  align-items: center;
}
.info {
  padding: 0 20px 20px;
}
.img-info {
  display: block;
  margin: 10px auto;
  width: 100%;
  border-radius: 10px;
}
.info h2 {
  color: #707070;
  margin-bottom: 10px;
  text-align: center;
}
.info p {
  font-size: 14px;
  color: #707070;
  margin-bottom: 10px;
  text-align: center;
}
.info .precio {
  font-size: 30px;
  font-weight: bold;
  color: #f85151;
  margin-bottom: 10px;
  display: block;
  text-align: center;
}
.info .fila {
  display: flex;
  align-items: center;
  justify-content: center;
}
.info .fila .p-button-success {
  height: 40px;
  border: none;
  padding: 0 10px;
  color: #fff;
  background-color: #28a745;
  margin-right: 10px;
}
.info .fila .p-button-info {
  background-color: #17a2b8;
  border: none;
  margin-right: 10px;
}
.whatsapp-link {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.whatsapp-logo {
  width: 60px;
  height: 60px;
}
.salir-imagen {
  color: #fff;
  cursor: pointer;
}

.formulario-comentarios {
  margin-top: 20px;
}
.comentarios-list {
  list-style-type: none;
  padding: 0;
}
.comentarios-list li {
  margin-bottom: 10px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}
.textarea-comentario {
  width: 100%;
  height: 100px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 10px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
}
.comentario-form {
  display: flex;
  flex-direction: column;
}
.select-tipo {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.btn-enviar {
  align-self: flex-end;
  margin-top: 10px;
}
</style>



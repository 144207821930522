<template>
  <div class="registro-container container mt-4">
    <h1 class="mb-4 text-center">Registro</h1>
    <div class="d-flex justify-content-start mb-3">
      <router-link to="/listas">
        <button type="button" class="btn btn-primary">Regresar</button>
      </router-link>
    </div>
    <form @submit.prevent="registrarUsuario" class="formulario-registro">
      <div class="form-group mb-3">
        <label for="nombres">Nombres:</label>
        <input type="text" id="nombres" v-model="usuario.nombres" required class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="apellidos">Apellidos:</label>
        <input type="text" id="apellidos" v-model="usuario.apellidos" required class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="telefono">Teléfono:</label>
        <input type="text" id="telefono" v-model="usuario.telefono" class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="direccion">Dirección:</label>
        <input type="text" id="direccion" v-model="usuario.direccion" class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="empresa">Empresa:</label>
        <input type="text" id="empresa" v-model="usuario.empresa" class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="apodo">Apodo:</label>
        <input type="text" id="apodo" v-model="usuario.apodo" class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="email">Correo Electrónico:</label>
        <input type="email" id="email" v-model="usuario.email" required class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="password">Contraseña:</label>
        <input type="password" id="password" v-model="usuario.password" required class="form-control">
      </div>
      <div class="form-group mb-3">
        <label for="c_password">Confirmar Contraseña:</label>
        <input type="password" id="c_password" v-model="usuario.c_password" required class="form-control">
      </div>
      <div class="form-group mb-4">
        <label for="rol">Seleccionar Rol:</label>
        <select id="rol" v-model="usuario.rol" required class="form-select">
          <option value="1">Admin</option>
          <option value="2">Cliente</option>
          <option value="3">Encargado de Ventas</option>
        </select>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-success">Registrarse</button>
      </div>
      <div v-if="errorMessage" class="alert alert-danger mt-3">{{ errorMessage }}</div>
    </form>
  </div>
</template>

<script>
import * as authService from "../../services/auth.service";
import { Buffer } from 'buffer';

export default {
  data() {
    return {
      usuario: {
        nombres: "",
        apellidos: "",
        telefono: "",
        direccion: "",
        empresa: "",
        apodo: "",
        email: "",
        password: "",
        c_password: "",
        rol: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    async registrarUsuario() {
      try {
        if (this.usuario.password !== this.usuario.c_password) {
          this.errorMessage = "Las contraseñas no coinciden.";
          return;
        }

        const response = await authService.registro(this.usuario);
        
        if (response.status === 201) {
          this.$router.push("/admin");
        }
      } catch (error) {
        this.errorMessage = "Error al registrar el usuario.";
        console.error(error);
      }
    },
  },
};
</script>

<style scoped>
.registro-container {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

h1 {
  color: #007bff;
  font-weight: bold;
}

.formulario-registro {
  max-width: 600px;
  margin: 0 auto;
}

.form-control, .form-select {
  border-radius: 30px;
}

.btn-success {
  width: 100%;
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 30px;
}

.btn-primary {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 30px;
}

.alert {
  border-radius: 30px;
}
</style>

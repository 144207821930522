import { http } from './http';

export default {
  // Obtener la lista de usuarios junto con sus detalles
  obtenerPosibles() {
    return http().get('/v1/posible');
  },

  // Obtener un usuario por ID
  obtenerPosiblePorId(id) {
    return http().get(`/v1/posible/${id}`);
  },

  // Crear un nuevo usuario
  crearPosible(posibleData) {
    return http().post('/v1/posible', posibleData);
  },

  // Actualizar un usuario por ID
  actualizarPosible(id, posibleData) {
    return http().put(`/v1/posible/${id}`, posibleData);
  },

  // Eliminar un usuario por ID
  eliminarPosible(id) {
    return http().delete(`/v1/posible/${id}`);
  },
}

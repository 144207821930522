<template>
  <div class="estadisticas-container">
    <h1 class="titulo">Estadísticas de Comentarios</h1>

    <router-link to="/estadisticas">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>

   <!-- Primer gráfico: Sentimientos Totales -->
<div class="chart-container" ref="totalSentimientosRef">
  <div class="header-container">
    <h2>Sentimientos Totales de Productos Tradicionales</h2>
    <button type="button" class="btn btn-success" @click="downloadTotalSentimientosPDF">
      <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
    </button>
  </div>
  <Pie 
    v-if="totalSentimientosData && totalSentimientosData.datasets[0].data.length" 
    :data="totalSentimientosData" 
    :options="chartOptions" 
  />
</div>


    <!-- Segundo gráfico: Comentarios por Usuario -->
    <div class="chart-container" ref="comentariosUsuarioRef">
  <h2>Comentarios por Usuario Tradicional</h2>
  <div class="form-group mb-4">
    <label for="userSelect" class="form-label">Seleccione un Usuario:</label>
    <select id="userSelect" class="form-control" v-model="selectedUserId" @change="updateComentariosUsuario">
      <option v-for="user in usuarios" :key="user.id" :value="user.id">{{ user.nombreCompleto }}</option>
    </select>
  </div>
  <button type="button" class="btn btn-success mt-2" @click="downloadComentariosUsuarioPDF">
    <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
  </button>
  <Bar v-if="comentariosUsuarioData && comentariosUsuarioData.datasets[0].data.length"
       :data="comentariosUsuarioData" :options="chartOptions" />
</div>

  <!-- tercer gráfico: Comentarios por Usuario -->
<div class="chart-container" ref="sentimientosProductoRef">
  <h2>Sentimientos por Producto Tradicional</h2>
  <div class="form-group mb-4">
    <label for="productSelect" class="form-label">Seleccione un Producto:</label>
    <select id="productSelect" class="form-control" v-model="selectedProductId" @change="updateSentimientosProducto">
      <option v-for="product in productos" :key="product.id" :value="product.id">{{ product.id }}. {{ product.nombre }}</option>
    </select>
  </div>
  <button type="button" class="btn btn-success mt-2" @click="downloadSentimientosProductoPDF">
    <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
  </button>
  <Bar v-if="sentimientosProductoData && sentimientosProductoData.datasets[0].data.length" 
       :data="sentimientosProductoData" :options="chartOptions" />
</div>


  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { Pie, Bar } from 'vue-chartjs';
import comentarioService from '@/services/comentario.service';
import usuarioService from '@/services/usuario.service';
import productoService from '@/services/producto.service';
import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);

export default {
  name: 'EstadisticasComentarioView',
  components: {
    Pie,
    Bar
  },
  setup() {
    const comentariosUsuarioRef = ref(null);
    const sentimientosProductoRef = ref(null); 

    const totalSentimientosData = ref(null);
    const comentariosUsuarioData = ref(null);
    const sentimientosProductoData = ref(null);
    const loaded = ref(false);

    const usuarios = ref([]);
    const productos = ref([]);
    const selectedUserId = ref(null);
    const selectedProductId = ref(null);

    

    const chartOptions = {
      responsive: true,
      aspectRatio: 3,
      plugins: {
        legend: {
          labels: {
            color: 'rgb(255, 99, 132)',
            font: {
              size: 14
            }
          }
        },
        tooltip: {
          backgroundColor: 'rgba(0,0,0,0.7)',
          titleFont: {
            size: 16
          },
          bodyFont: {
            size: 14
          }
        }
      },
      scales: {
        x: {
          ticks: {
            color: 'rgba(255, 99, 132, 1)',
            font: {
              size: 14
            }
          }
        },
        y: {
          ticks: {
            color: 'rgba(255, 99, 132, 1)',
            font: {
              size: 14
            }
          }
        }
      }
    };

    const procesarDatos = (comentarios) => {
      const totalSentimientos = { positive: 0, negative: 0, neutral: 0 };

      comentarios.forEach(comentario => {
        totalSentimientos[comentario.sentiment]++;
      });

      totalSentimientosData.value = {
        labels: ['Positive', 'Negative', 'Neutral'],
        datasets: [{
          data: [totalSentimientos.positive, totalSentimientos.negative, totalSentimientos.neutral],
          backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
        }]
      };

      loaded.value = true;
    };

    const obtenerComentarios = async () => {
      try {
        const response = await comentarioService.obtenerComentariosPorTipo('tradicional');
        procesarDatos(response.data);
      } catch (error) {
        console.error("Error al obtener los comentarios:", error);
      }
    };

    const updateComentariosUsuario = async () => {
      try {
        const response = await comentarioService.obtenerComentariosPorUsuario(selectedUserId.value);
        const comentarios = response.data;

        const sentimientos = { positive: 0, negative: 0, neutral: 0 };
        comentarios.forEach(comentario => {
          sentimientos[comentario.sentiment]++;
        });

        comentariosUsuarioData.value = {
          labels: ['Positive', 'Negative', 'Neutral'],
          datasets: [{
            label: `Usuario ${selectedUserId.value}`,
            data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
            backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
          }]
        };

        loaded.value = true;
      } catch (error) {
        console.error("Error al obtener los comentarios por usuario:", error);
      }
    };

    const updateSentimientosProducto = async () => {
      try {
        const response = await comentarioService.obtenerComentariosPorProducto(selectedProductId.value);
        const comentarios = response.data;

        const sentimientos = { positive: 0, negative: 0, neutral: 0 };
        comentarios.forEach(comentario => {
          sentimientos[comentario.sentiment]++;
        });

        sentimientosProductoData.value = {
          labels: ['Positive', 'Negative', 'Neutral'],
          datasets: [{
            label: `Producto ${selectedProductId.value}`,
            data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
            backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
          }]
        };

        loaded.value = true;
      } catch (error) {
        console.error("Error al obtener los comentarios por producto:", error);
      }
    };

    //---------------------------------- REPORTES DE PDF GRAFICO 1-------------------------//
   const downloadTotalSentimientosPDF = async () => {
  const pdf = new jsPDF('p', 'mm', 'letter');
  const pageWidth = pdf.internal.pageSize.getWidth();
  const headerHeight = 30;

  const headerImg = require('@/assets/cabecera.jpg');
  const footerImg = require('@/assets/piepagina.jpg');

  const chartContainer = document.querySelector('.chart-container');
  const button = chartContainer.querySelector('button'); // Selecciona el botón

  try {
    // Oculta el botón antes de la captura
    button.style.display = 'none';

    const canvas = await html2canvas(chartContainer);
    const imgData = canvas.toDataURL('image/png');

    // Restaura el botón después de la captura
    button.style.display = '';

    pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

    const imgWidth = pageWidth - 40;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

    const total = totalSentimientosData.value.datasets[0].data.reduce((a, b) => a + b, 0);
    const [positive, negative, neutral] = totalSentimientosData.value.datasets[0].data;

    const descripcion = `
Descripción del gráfico:
- Comentarios positivos: ${positive} (${((positive / total) * 100).toFixed(2)}%)
- Comentarios negativos: ${negative} (${((negative / total) * 100).toFixed(2)}%)
- Comentarios neutrales: ${neutral} (${((neutral / total) * 100).toFixed(2)}%)
Total de comentarios: ${total}
    `;

    pdf.setFontSize(12);
    pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);
    pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
    pdf.save('sentimientos_totales.pdf');
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};

// -----------------------REPORTES DE PDF GRAFICO 2---------------------------//
   const downloadComentariosUsuarioPDF = async () => {
  try {
    const pdf = new jsPDF('p', 'mm', 'letter');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const headerHeight = 30;

    const headerImg = require('@/assets/cabecera.jpg');
    const footerImg = require('@/assets/piepagina.jpg');

    // Acceder a la referencia correctamente
    const chartContainer = comentariosUsuarioRef.value;

    if (!chartContainer) {
      console.error("No se encontró el contenedor del gráfico.");
      return;
    }

    // Seleccionar el botón dentro del contenedor
    const button = chartContainer.querySelector('button');

    // Ocultar el botón antes de la captura
    if (button) button.style.display = 'none';

    const canvas = await html2canvas(chartContainer);
    const imgData = canvas.toDataURL('image/png');

    // Restaurar el botón después de la captura
    if (button) button.style.display = '';

    pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

    const imgWidth = pageWidth - 40;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

    const data = comentariosUsuarioData.value.datasets[0].data;
    const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
    `;

    pdf.setFontSize(12);
    pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);

    pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
    pdf.save('comentarios_usuario.pdf');
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};


// -----------------------REPORTES DE PDF GRAFICO 3---------------------------//
const downloadSentimientosProductoPDF = async () => {
  try {
    const pdf = new jsPDF('p', 'mm', 'letter');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const headerHeight = 30;

    const headerImg = require('@/assets/cabecera.jpg');
    const footerImg = require('@/assets/piepagina.jpg');

    const chartContainer = sentimientosProductoRef.value; // Usar la referencia correctamente

    if (!chartContainer) {
      console.error("No se encontró el contenedor del gráfico.");
      return;
    }

    // Seleccionar el botón dentro del contenedor
    const button = chartContainer.querySelector('button');

    // Ocultar el botón antes de la captura
    if (button) button.style.display = 'none';

    const canvas = await html2canvas(chartContainer);
    const imgData = canvas.toDataURL('image/png');

    // Restaurar el botón después de la captura
    if (button) button.style.display = '';

    pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

    const imgWidth = pageWidth - 40;
    const imgHeight = (canvas.height * imgWidth) / canvas.width;
    pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

    const data = sentimientosProductoData.value.datasets[0].data;

    const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
    `;

    pdf.setFontSize(12);
    pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);

    pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
    pdf.save('sentimientos_producto.pdf');
  } catch (error) {
    console.error('Error al generar el PDF:', error);
  }
};


    onMounted(async () => {
      await obtenerComentarios();
      usuarios.value = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
      const productosResponse = await productoService.obtenerProductos();
      productos.value = productosResponse.data;
    });

    return {
      comentariosUsuarioRef,
      sentimientosProductoRef,
    
      totalSentimientosData,
      comentariosUsuarioData,
      sentimientosProductoData,
      chartOptions,
      loaded,
      usuarios,
      productos,
      selectedUserId,
      selectedProductId,
      updateComentariosUsuario,
      updateSentimientosProducto,
      downloadTotalSentimientosPDF,
      downloadComentariosUsuarioPDF,
      downloadSentimientosProductoPDF,
    };
  }
};
</script>

<style scoped>
.estadisticas-container {
  padding: 20px;
  max-width: 1200px;
  margin: auto;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.titulo {
  text-align: center;
  color: #2c3e50;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.chart-container {
  margin-bottom: 40px;
}

.chart-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-family: 'Arial', sans-serif;
}

.btn-secondary {
  display: flex;
  align-items: center;
}

.btn-secondary i {
  margin-right: 0.5rem;
}

.form-group {
  margin-bottom: 20px;
}
</style>

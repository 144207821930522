<template>
    <div>
      <router-link to="/visitas">
        <button type="button" class="btn btn-primary">Regresar</button>
      </router-link>
      <h3>Seleccione un Usuario</h3>
      <select v-model="selectedUser" @change="fetchComments">
        <option v-for="user in users" :key="user.id" :value="user.id">{{ user.nombreCompleto }}</option>
      </select>
  
      <div v-if="comments.length > 0">
        <h3>Seleccione un Producto</h3>
        <select v-model="selectedProduct" @change="filterComments">
          <option v-for="product in products" :key="product.id" :value="product.id">{{ product.id }} - {{ product.nombre }}</option>
        </select>
  
        
        <div v-if="filteredComments.length > 0">
        <h3>Gráfico de Sentimientos</h3>
        <div class="chart-container" ref="chartRef">
          <Bar :data="chartData" :options="chartOptions" />
        </div>
        <button type="button" class="btn btn-success mt-2" @click="downloadChartPDF">
          <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
        </button>
      </div>

      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted, watch, computed } from 'vue';
  import { Bar } from 'vue-chartjs';
  import comentariominiService from '@/services/comentariomini.service.js';
  import usuarioService from '@/services/usuario.service.js';
  import productominiService from '@/services/productomini.service.js';
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import html2canvas from 'html2canvas';
  import jsPDF from 'jspdf';
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'ComentarioMiniView',
    components: {
      Bar
    },
    setup() {
      const users = ref([]);
      const selectedUser = ref(null);
      const comments = ref([]);
      const selectedProduct = ref(null);
      const filteredComments = ref([]);
      const products = ref([]);
      const chartRef = ref(null);

      const fetchUsers = async () => {
        try {
          const response = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
          users.value = response;
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      };
  
      const fetchProducts = async () => {
        try {
          const response = await productominiService.obtenerProductosmini();
          products.value = response.data;
        } catch (error) {
          console.error("Error fetching products:", error);
        }
      };
  
      const fetchComments = async () => {
        if (selectedUser.value) {
          try {
            const response = await comentariominiService.obtenerComentariosPorUsuarioMini(selectedUser.value);
            comments.value = response.data;
            console.log('Comments:', comments.value);
  
            // Obtener productos únicos y sus nombres
            const productIds = [...new Set(comments.value.map(comment => comment.producto_mini_id))];
            products.value = products.value.filter(product => productIds.includes(product.id));
            console.log('Products:', products.value);
  
            filteredComments.value = [];
            selectedProduct.value = null;
          } catch (error) {
            console.error("Error fetching comments:", error);
          }
        }
      };
  
      const filterComments = () => {
        console.log('Selected Product:', selectedProduct.value);
        if (selectedProduct.value) {
          filteredComments.value = comments.value.filter(comment => {
            console.log('Comparing:', comment.producto_mini_id, 'with', selectedProduct.value);
            return comment.producto_mini_id == selectedProduct.value;
          });
          console.log('Filtered Comments:', filteredComments.value);
        }
      };
  
      const chartData = computed(() => {
        const sentiments = filteredComments.value.reduce((acc, comment) => {
          acc[comment.sentiment] = (acc[comment.sentiment] || 0) + 1;
          return acc;
        }, {});
  
        console.log('Sentiments:', sentiments);
  
        return {
          labels: Object.keys(sentiments),
          datasets: [
            {
              label: 'Comentarios',
              backgroundColor: ['#42A5F5', '#66BB6A', '#FFA726'],
              data: Object.values(sentiments)
            }
          ]
        };
      });
  
      const chartOptions = computed(() => ({
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          },
          y: {
            beginAtZero: true,
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          }
        },
        plugins: {
          legend: {
            labels: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(0,0,0,0.7)',
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            }
          }
        }
      }));

      const downloadChartPDF = async () => {
      try {
        const pdf = new jsPDF('p', 'mm', 'letter');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const headerHeight = 30;

        const headerImg = require('@/assets/cabecera.jpg');
        const footerImg = require('@/assets/piepagina.jpg');
        const chartContainer = chartRef.value;

        if (!chartContainer) {
          console.error('No se encontró el contenedor del gráfico.');
          return;
        }

        const button = chartContainer.querySelector('button');
        if (button) button.style.display = 'none';

        const canvas = await html2canvas(chartContainer);
        const imgData = canvas.toDataURL('image/png');
        if (button) button.style.display = '';

        pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

        const imgWidth = pageWidth - 40;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

        const user = users.value.find(u => u.id === selectedUser.value)?.nombreCompleto || 'N/A';
        const product = products.value.find(p => p.id === selectedProduct.value)?.nombre || 'N/A';

        const descripcion = `
Usuario: ${user}
Producto: ${product}

Descripción del gráfico:
- Positivos: ${chartData.value.datasets[0]?.data[0] || 0}
- Negativos: ${chartData.value.datasets[0]?.data[1] || 0}
- Neutrales: ${chartData.value.datasets[0]?.data[2] || 0}
        `;

        pdf.setFontSize(12);
        pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);
        pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
        pdf.save('grafico_comentarios_mini.pdf');
      } catch (error) {
        console.error('Error al generar el PDF:', error);
      }
    };
  
      onMounted(async () => {
        await fetchUsers();
        await fetchProducts();
      });
  
      watch(selectedProduct, filterComments);
  
      return {
        users,
        selectedUser,
        comments,
        selectedProduct,
        filteredComments,
        products,
        fetchComments,
        filterComments,
        chartData,
        chartOptions,
        chartRef,
        downloadChartPDF
      };
    }
  };
  </script>
  
  <style scoped>
  .chart-container {
    height: 400px;
  }
  </style>
  
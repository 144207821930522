<template>
    <router-link to="/encargado">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>
    <h1>Encargado</h1>
    <b-button @click="toggleWeekends" class="btn btn-primary">Lunes-Viernes</b-button>
    <b-button @click="abrirModalAgregarEvento" class="btn btn-primary">Agregar Evento</b-button>
  
    <!-- Modal de registro de evento -->
    <div class="modal fade" id="modalAgregarEvento" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalAgregarEventoLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalAgregarEventoLabel">Registrar Evento</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="guardarNuevoEvento">
                        <div class="mb-3">
                            <label for="cal-titulo" class="form-label">Título:</label>
                            <input type="text" id="cal-titulo" v-model="nuevoEvento.titulo" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="cal-descripcion" class="form-label">Descripción:</label>
                            <input type="text" id="cal-descripcion" v-model="nuevoEvento.descripcion" class="form-control" required>
                        </div>
                        <div class="mb-3">
                            <label for="cal-inicio" class="form-label">Fecha y Hora (Opcional):</label>
                            <input type="datetime-local" id="cal-inicio" v-model="nuevoEvento.inicio" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="cal-colorfondo" class="form-label">Color de Fondo:</label>
                            <select id="cal-colorfondo" v-model="nuevoEvento.colorfondo" class="form-select">
                                <option value="#FFFF00" style="background-color: #FFFF00;">Amarillo</option>
                                <option value="#FF0000" style="background-color: #FF0000;">Rojo</option>
                                <option value="#00FF00" style="background-color: #00FF00;">Verde</option>
                                <option value="#0000FF" style="background-color: #0000FF;">Azul</option>
                                <option value="#800080" style="background-color: #800080;">Morado</option>
                                <option value="#000000" style="background-color: #000000;">Negro</option>
                            </select>
                        </div>
                        <div class="mb-3">
                            <label for="cal-colortexto" class="form-label">Color de Texto:</label>
                            <input type="color" id="cal-colortexto" v-model="nuevoEvento.colortexto" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="cal-fecha" class="form-label">Fecha:</label>
                            <input type="date" id="cal-fecha" v-model="nuevoEvento.fecha" class="form-control">
                        </div>
                        <div class="mb-3">
                            <label for="cal-user_id" class="form-label">ID de Usuario:</label>
                            <input type="text" id="cal-user_id" v-model="nuevoEvento.user_id" class="form-control">
                        </div>
                        <button type="submit" class="btn btn-primary">Guardar</button>
                        <div v-if="errorMessage" class="error-message text-danger">{{ errorMessage }}</div>
                    </form>
                    <div v-if="saveMessage" class="save-message text-success">{{ saveMessage }}</div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
  
    <!-- Modal de detalles del evento -->
    <div class="modal fade" id="modalDetallesEvento" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalDetallesEventoLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="modalDetallesEventoLabel">Detalles del Evento</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <p><strong>Título:</strong> {{ eventoActual.title }}</p>
                    <p><strong>Descripción:</strong> {{ eventoActual.description }}</p>
                    <p><strong>Fecha y Hora:</strong> {{ eventoActual.start }}</p>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                </div>
            </div>
        </div>
    </div>
  
    <p></p>
    <FullCalendar :options="calendarOptions" />
  </template>
  <script>
  import eventoService from '@/services/evento.service';
  import 'bootstrap/dist/css/bootstrap.min.css';
  import 'bootstrap/js/dist/modal';
  import 'bootstrap/dist/js/bootstrap.bundle.min.js';
  import FullCalendar from '@fullcalendar/vue3';
  import dayGridPlugin from '@fullcalendar/daygrid';
  import interactionPlugin from '@fullcalendar/interaction';
  import timeGrid from '@fullcalendar/timegrid';
  import multiMonthPlugin from '@fullcalendar/multimonth';
  import listPlugin from '@fullcalendar/list';
  
  export default {
      components: {
          FullCalendar
      },
      data() {
          return {
              ordenAscendente: true,
              nuevoEvento: {
                  titulo: '',
                  descripcion: '',
                  inicio: '',
                  colorfondo: '#FFFF00',  // Default to amarillo
                  colortexto: '#FFFFFF',  // Default to blanco
                  fecha: '',
                  user_id: ''
              },
              eventoActual: {},
              saveMessage: '',
              calendarOptions: {
                  plugins: [dayGridPlugin, interactionPlugin, timeGrid, multiMonthPlugin, listPlugin],
                  initialView: 'dayGridMonth',
                  editable: true,
                  selectable: true,
                  selectMirror: true,
                  dayMaxEvents: true,
                  weekends: true,
                  allDaySlot: false,
                  locale: 'es',
                  modalShow: false,
                  eventDidMount: this.eventDidMount,
                  headerToolbar: {
                      left: 'prev,next, today, multiMonthYear,dayGridMonth,dayGridWeek,dayGridDay',
                      center: 'title',
                      right: 'timeGridWeek,timeGridDay, listWeek'
                  },
                  slotMinTime: '06:00:00',
                  slotMaxTime: '24:00:00',
                  height: 'auto',
                  buttonText: {
                      timeGridWeek: 'Semana-Agenda',
                      timeGridDay: 'Dia-Agenda',
                      today: 'Hoy',
                      month: 'Mes',
                      week: 'Semana',
                      day: 'Día',
                      list: 'Lista',
                      multiMonthYear: 'Año'
                  },
                  dateClick: this.handleDateClick,
                  eventClick: this.handleEventClick,
                  events: []
              }
          };
      },
      mounted() {
          this.obtenerEventos();
          import('bootstrap').then((bootstrap) => {
              this.bootstrap = bootstrap;
          });
      },
      methods: {
          async obtenerEventos() {
              try {
                  const respuesta = await eventoService.obtenerEventos();
                  this.eventos = respuesta.data;
                  const eventosVue = this.eventos.map(evento => ({
                      title: evento.titulo,
                      description: evento.descripcion,
                      start: evento.inicio,
                      backgroundColor: evento.colorfondo,
                      textColor: evento.colortexto,
                      date: evento.fecha
                  }));
                  this.calendarOptions.events = eventosVue;
              } catch (error) {
                  console.error('Error al obtener eventos:', error);
              }
          },
          abrirModalAgregarEvento() {
              this.resetNuevoEvento();
              const modalElement = document.getElementById('modalAgregarEvento');
              const modal = new this.bootstrap.Modal(modalElement);
              modal.show();
          },
          resetNuevoEvento() {
              this.nuevoEvento = {
                  titulo: '',
                  descripcion: '',
                  inicio: '',
                  colorfondo: '#FFFF00',
                  colortexto: '#FFFFFF',
                  fecha: '',
                  user_id: ''
              };
          },
          async guardarNuevoEvento() {
              try {
                  const response = await eventoService.crearNuevoEvento(this.nuevoEvento);
                  this.saveMessage = 'Evento guardado exitosamente';
                  this.errorMessage = '';
                  this.obtenerEventos(); // Actualiza el calendario con el nuevo evento
                  const modalElement = document.getElementById('modalAgregarEvento');
                  const modal = this.bootstrap.Modal.getInstance(modalElement);
                  modal.hide();
              } catch (error) {
                  this.errorMessage = 'Error al guardar el evento';
                  this.saveMessage = '';
              }
          },
          handleDateClick(arg) {
              this.resetNuevoEvento();
              this.nuevoEvento.fecha = arg.dateStr; // Establece la fecha del nuevo evento
              const modalElement = document.getElementById('modalAgregarEvento');
              const modal = new this.bootstrap.Modal(modalElement);
              modal.show();
          },
          handleEventClick(info) {
              const clickedEvent = info.event;
              this.eventoActual = {
                  title: clickedEvent.title,
                  start: clickedEvent.start.toLocaleString(),
                  description: clickedEvent.extendedProps.description || 'Sin descripción'
              };
              const modalElement = document.getElementById('modalDetallesEvento');
              const modal = new this.bootstrap.Modal(modalElement);
              modal.show();
          }
      }
  };
  </script>
  <style>
  .modal-content {
      border-radius: 15px;
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  }
  
  .modal-header {
      background-color: #007bff;
      color: white;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
  }
  
  .modal-footer {
      border-bottom-left-radius: 15px;
      border-bottom-right-radius: 15px;
  }
  
  .btn-primary {
      background-color: #007bff;
      border-color: #007bff;
  }
  
  .btn-secondary {
      background-color: #6c757d;
      border-color: #6c757d;
  }
  
  .error-message {
      color: red;
  }
  
  .save-message {
      color: green;
  }
  
  .modal-body p {
      margin-bottom: 0.5rem;
  }
  
  .modal-body p strong {
      color: #007bff;
  }
  
  </style>
  
<template>
  <div class="container mt-4 chats-container">
    <div class="header-section text-center">
      <h1 class="mb-4">Chats Vera Jaus</h1>
    </div>

    <div class="mb-4 search-section">
      <input type="text" v-model="filtroBusqueda" placeholder="Buscar usuarios" class="form-control search-input" />
      <button class="btn btn-primary ms-2" @click="ordenarPorNombres">
        Ordenar por Nombres
        <i class="fas" :class="ordenAscendente ? 'fa-sort-alpha-down' : 'fa-sort-alpha-up'"></i>
      </button>
    </div>

    <div class="table-responsive">
      <table class="table custom-table">
        <thead class="bg-primary text-white">
          <tr>
            <th>ID</th>
            <th>Nombres</th>
            <th>Apellidos</th>
            <th>Empresa</th>
            <th>WhatsApp</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="usuario in usuariosFiltrados" :key="usuario.id">
            <td>{{ usuario.id }}</td>
            <td>{{ usuario.nombres }}</td>
            <td>{{ usuario.apellidos }}</td>
            <td>{{ usuario.empresa || 'No tiene' }}</td>
            <td>
              <div class="whatsapp-button-container">
                <button class="btn btn-success whatsapp-button" @click="redirigirWhatsApp(usuario.telefono)">
                  <i class="fab fa-whatsapp"></i> WhatsApp
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import usuarioService from '@/services/usuario.service.js';
import '@fortawesome/fontawesome-free/css/all.css';

export default {
  data() {
    return {
      usuarios: [], // inicializa como un array vacío
      filtroBusqueda: '',
      ordenAscendente: true,
    };
  },
  computed: {
    usuariosFiltrados() {
      if (!this.filtroBusqueda) return this.usuarios;
      return this.usuarios.filter(usuario => {
        return (
          usuario.nombres.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          usuario.apellidos.toLowerCase().includes(this.filtroBusqueda.toLowerCase()) ||
          (usuario.empresa && usuario.empresa.toLowerCase().includes(this.filtroBusqueda.toLowerCase())) ||
          usuario.id.toString().includes(this.filtroBusqueda)
        );
      });
    },
  },
  mounted() {
    this.obtenerUsuarios();
  },
  methods: {
    async obtenerUsuarios() {
      try {
        const response = await usuarioService.obtenerUsuarios();
        this.usuarios = response.data.filter(usuario => usuario.rol === 2);
      } catch (error) {
        console.error('Error al obtener usuarios:', error);
      }
    },
    ordenarPorNombres() {
      if (Array.isArray(this.usuarios)) {
        this.usuarios.sort((a, b) => {
          const nombreA = a.nombres.toUpperCase();
          const nombreB = b.nombres.toUpperCase();
          if (nombreA < nombreB) return this.ordenAscendente ? -1 : 1;
          if (nombreA > nombreB) return this.ordenAscendente ? 1 : -1;
          return 0;
        });
        this.ordenAscendente = !this.ordenAscendente;
      } else {
        console.error('La propiedad usuarios no es un array.');
      }
    },
    redirigirWhatsApp(numeroTelefono) {
      const codigoPais = '+591';
      const numeroFormateado = `${codigoPais}${numeroTelefono.replace(/\D/g, '')}`;
      const urlWhatsApp = `https://wa.me/${numeroFormateado}`;
      window.open(urlWhatsApp, '_blank');
    },
  },
};
</script>

<style scoped>
.chats-container {
  background-color: #f8f9fa;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.header-section {
  margin-bottom: 30px;
}

.header-section h1 {
  font-size: 2.5rem;
  color: #007bff;
  font-weight: bold;
}

.search-section {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.search-input {
  width: 300px;
  border-radius: 30px;
  padding: 10px 20px;
  border: 1px solid #ced4da;
}

.table {
  border-collapse: separate;
  border-spacing: 0 15px;
}

.custom-table th, .custom-table td {
  padding: 10px;
  text-align: center;
}

.custom-table thead th {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 10px;
}

.custom-table tbody tr {
  transition: all 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.custom-table tbody tr:hover {
  background-color: #e8f0ff;
  transform: translateY(-3px);
}

.whatsapp-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.whatsapp-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  padding: 8px 15px;
  border-radius: 25px;
  transition: background-color 0.3s ease, transform 0.2s ease;
  width: 120px; /* Ajusta el ancho según tu necesidad */
}


.whatsapp-button:hover {
  background-color: #28a745;
  transform: translateY(-2px);
}

.whatsapp-button i {
  font-size: 1.2rem;
  margin-right: 5px;
}


.table-responsive {
  overflow-x: auto;
}
</style>

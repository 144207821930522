<template>
  <div>
    <h1 class="mb-4">Gustos del Cliente</h1>
    <router-link to="/estadisticas"> 
       <button type="button" class="btn btn-secondary mb-4">
         <i class="bi bi-arrow-left-circle"></i> Regresar
       </button>
    </router-link>

    <button type="button" class="btn btn-success mt-4" @click="downloadPDF">
      <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
    </button>

    <div class="form-group mb-4">
       <label for="userSelect" class="form-label">Seleccione un Cliente:</label>
       <select id="userSelect" class="form-control" v-model="selectedUserId" @change="loadData">
         <option v-for="user in usuarios" :key="user.id" :value="user.id">{{ user.id }} - {{ user.nombreCompleto }}</option>
       </select>
    </div>

    <div class="row mt-3 justify-content-center">
      <div class="card border border-dark col-12 col-lg-10">
        <div class="card-header bg-dark text-white text-center">Gráfico de Gustos del Cliente</div>
        <div class="card-body chart-container" ref="chartContainer">
          <Bar v-if="loaded" :options="chartOptions" :data="chartData" ref="bar" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usuarioService from '@/services/usuario.service.js';
import { Bar } from 'vue-chartjs';
import contadorService from '@/services/contador.service';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default {
  name: 'click',
  components: { Bar },
  data() {
    return {
      usuarios: [],
      selectedUserId: null,
      selectedUserName: '',
      loaded: false,
      chartData: null,
      chartOptions: {
        responsive: true,
        aspectRatio: 2,  // Cambié el aspecto para hacerlo más ancho
        plugins: {
          legend: {
            position: 'top',
            labels: {
              color: '#343a40',
              font: {
                size: 16
              }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(0, 0, 0, 0.7)',
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: '#343a40', // Ajuste del color de las etiquetas
              font: {
                size: 14
              }
            },
            title: { // Añadí un título para el eje X
              display: true,
              text: 'Estilos de Muebles',
              color: '#343a40',
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          },
          y: {
            ticks: {
              color: '#343a40', // Ajuste del color de las etiquetas
              font: {
                size: 14
              }
            },
            title: { // Añadí un título para el eje Y
              display: true,
              text: 'Número de Interacciones',
              color: '#343a40',
              font: {
                size: 16,
                weight: 'bold'
              }
            }
          }
        }
      }
    };
  },
  async mounted() {
    // Carga la lista de usuarios cuando se monta el componente
    this.usuarios = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
    // Cargar los datos de un usuario por defecto
    if (this.usuarios.length > 0) {
      this.selectedUserId = this.usuarios[0].id;
      this.selectedUserName = this.usuarios[0].nombreCompleto;
      this.loadData();
    }
  },
  methods: {
    async loadData() {
      this.loaded = false;
      try {
        const response = await contadorService.getContador(this.selectedUserId);
        const contadores = response.data;
        this.chartData = {
          labels: ['Tradicional', 'Minimalista', 'Transicional'],
          datasets: [{
            label: `Cliente ${this.selectedUserId}`,
            data: [contadores.contadortradicional, contadores.contadorminimalista, contadores.contadortransicional],
            backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56'],
          }],
        };
        this.selectedUserName = this.usuarios.find(user => user.id === this.selectedUserId).nombreCompleto;
        this.loaded = true;
      } catch (error) {
        console.error('Error al obtener los datos de los contadores', error);
      }
    },
    async downloadPDF() {
      // Código para generar el PDF con el gráfico
      const pdf = new jsPDF('p', 'mm', 'letter');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const headerHeight = 30;
      const footerHeight = 35;
      const headerImg = require('@/assets/cabecera.jpg');
      const footerImg = require('@/assets/piepagina.jpg');

      const chartContainer = this.$refs.chartContainer;
      const canvas = await html2canvas(chartContainer);
      const imgData = canvas.toDataURL('image/png');

      const addHeader = (doc) => {
        doc.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);
      };

      const addFooter = (doc) => {
        doc.addImage(footerImg, 'PNG', 0, pageHeight - footerHeight, pageWidth, footerHeight);
      };

      addHeader(pdf);
      addFooter(pdf);

      pdf.setFontSize(14);
      pdf.text(`Reporte de Gustos del Cliente: ${this.selectedUserName}`, 20, headerHeight + 10);

      const imgWidth = pageWidth - 30;
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 15, headerHeight + 20, imgWidth, imgHeight);

      const data = this.chartData.datasets[0].data;
      const descripcion = `
        El cliente ${this.selectedUserName} ha interactuado con las siguientes categorías:
        - Tradicional: ${data[0]} veces
        - Minimalista: ${data[1]} veces
        - Transicional: ${data[2]} veces
      `;

      pdf.setFontSize(12);
      pdf.text(descripcion, 15, headerHeight + 20 + imgHeight + 10);
      pdf.save(`reporte_${this.selectedUserName}.pdf`);
    }
  }
};
</script>

<style scoped>
/* Estilos adicionales para mejorar la estética */
body {
  background-color: #f8f9fa;
}

h1 {
  color: #343a40;
  font-size: 2.5rem;
  text-align: center;
}

.card {
  border-radius: 0.5rem;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-header {
  font-weight: bold;
  font-size: 1.25rem;
}

.chart-container {
  width: 100%; /* Se ajusta al ancho completo del contenedor */
  height: 400px; /* Altura fija para el gráfico */
}

.btn-secondary, .btn-success {
  display: flex;
  align-items: center;
}

.btn-secondary i, .btn-success i {
  margin-right: 0.5rem;
}

.form-label {
  font-weight: bold;
}
</style>

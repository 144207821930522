import { http } from './http';

export default {
  // Crear un nuevo comentario ----todos
  crearComentario(comentarioData) {
    return http().post('/v1/comments', comentarioData);
  },

  // Obtener todos los comentarios---- todos
  obtenerComentarios() {
    return http().get('/v1/comments');
  },
   // Obtener comentarios por product_id---tradicional
   obtenerComentariosPorProducto(productId) {
    return http().get(`/v1/comments?product_id=${productId}`);
  },
  



  // Obtener comentarios por product_id---minimalista
  obtenerComentariosPorProductoMini(productoMiniId) {
    return http().get(`/v1/comments?producto_mini_id=${productoMiniId}`);
  },
  
   // Obtener comentarios por product_id---transicional
  obtenerComentariosPorProductoTransi(productoTransiId) {
    return http().get(`/v1/comments?producto_transi_id=${productoTransiId}`);
  },



  
  obtenerComentariosPorTipo(tipo) {
    return http().get(`/v1/comments?tipo=${tipo}`);
  },

  obtenerComentariosPorUsuario(userId) {
    return http().get(`/v1/comments?user_id=${userId}`);
  }

 }


<template>
  <div :class="containerClass" @click="onWrapperClick">
    <AppTopBar @menu-toggle="onMenuToggle" />
    <div class="layout-sidebar" @click="onSidebarClick">
      <AppMenu :model="menu" @menuitem-click="onMenuItemClick" />
    </div>

    <div class="layout-main-container">
      <div class="layout-main">
        <router-view />
      </div>
      <AppFooter />
    </div>

    <AppConfig :layoutMode="layoutMode" @layout-change="onLayoutChange" />
    <transition name="layout-mask">
      <div class="layout-mask p-component-overlay" v-if="mobileMenuActive"></div>
    </transition>
  </div>
</template>

<script>
import AppTopBar from "./AppTopbar.vue";
import AppMenu from "./AppMenu.vue";
import AppConfig from "./AppConfig.vue";
import AppFooter from "./AppFooter.vue";

export default {
  emits: ["change-theme"],
  data() {
    return {
      layoutMode: "static",
      staticMenuInactive: false,
      overlayMenuActive: false,
      mobileMenuActive: false,
      menu: [
        {
          label: "ADMINISTRADOR",
          items: [
            {
              label: "Admin",
              icon: "pi pi-fw pi-home",
              to: "/admin",
            },
            {
              label: "Perfil",
              icon: "pi pi-fw pi-user",
              to: "/about",
            },
          ],
        },
        {
          label: "CRM",
          icon: "pi pi-fw pi-sitemap",
          items: [
            {
              label: "Panel",
              icon: "pi pi-fw pi-id-card",
              to: "/panel",
            },
            {
              label: "Chats",
              icon: "pi pi-fw pi-comments",
              to: "/chats",
            },
            {
              label: "Calendario",
              icon: "pi pi-fw pi-calendar",
              to: "/calendario",
            },
            {
              label: "Listas",
              icon: "pi pi-fw pi-list",
              to: "/listas",
            },
            {
              label: "Chat Clientes",
              icon: "pi pi-fw pi-users",
              to: "/clienteschats",
            },
            {
              label: "Estadisticas",
              icon: "pi pi-fw pi-chart-bar",
              to: "/estadisticas",
            },
            {
              label: "Posibles Clientes",
              icon: "pi pi-fw pi-user-plus",
              to: "/posiblesclientes",
            },
            /*
            {
              label: "Tareas",
              icon: "pi pi-fw pi-id-card",
              to: "/tareas",
            },
            */
            {
              label: "Reuniones",
              icon: "pi pi-fw pi-briefcase",
              to: "/reuniones",
            },
            /*
            {
              label: "Informes",
              icon: "pi pi-fw pi-id-card",
              to: "/informes",
            },
              */
            {
              label: "Detallado",
              icon: "pi pi-fw pi-eye",
              to: "/visitas",
            },
         
          ],
        },
      ],
    };
  },
  watch: {
    $route() {
      this.menuActive = false;
      this.$toast.removeAllGroups();
    },
  },
  methods: {
    onWrapperClick() {
      if (!this.menuClick) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }

      this.menuClick = false;
    },
    onMenuToggle() {
      this.menuClick = true;

      if (this.isDesktop()) {
        if (this.layoutMode === "overlay") {
          if (this.mobileMenuActive === true) {
            this.overlayMenuActive = true;
          }

          this.overlayMenuActive = !this.overlayMenuActive;
          this.mobileMenuActive = false;
        } else if (this.layoutMode === "static") {
          this.staticMenuInactive = !this.staticMenuInactive;
        }
      } else {
        this.mobileMenuActive = !this.mobileMenuActive;
      }

      event.preventDefault();
    },
    onSidebarClick() {
      this.menuClick = true;
    },
    onMenuItemClick(event) {
      if (event.item && !event.item.items) {
        this.overlayMenuActive = false;
        this.mobileMenuActive = false;
      }
    },
    onLayoutChange(layoutMode) {
      this.layoutMode = layoutMode;
    },
    addClass(element, className) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    },
    removeClass(element, className) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    },
    isDesktop() {
      return window.innerWidth >= 992;
    },
    isSidebarVisible() {
      if (this.isDesktop()) {
        if (this.layoutMode === "static") return !this.staticMenuInactive;
        else if (this.layoutMode === "overlay") return this.overlayMenuActive;
      }

      return true;
    },
  },
  computed: {
    containerClass() {
      return [
        "layout-wrapper",
        {
          "layout-overlay": this.layoutMode === "overlay",
          "layout-static": this.layoutMode === "static",
          "layout-static-sidebar-inactive":
            this.staticMenuInactive && this.layoutMode === "static",
          "layout-overlay-sidebar-active":
            this.overlayMenuActive && this.layoutMode === "overlay",
          "layout-mobile-sidebar-active": this.mobileMenuActive,
          "p-input-filled": this.$primevue.config.inputStyle === "filled",
          "p-ripple-disabled": this.$primevue.config.ripple === false,
        },
      ];
    },
    logo() {
      return this.$appState.darkTheme
        ? "images/logo-white.svg"
        : "images/logo.svg";
    },
  },
  beforeUpdate() {
    if (this.mobileMenuActive)
      this.addClass(document.body, "body-overflow-hidden");
    else this.removeClass(document.body, "body-overflow-hidden");
  },
  components: {
    AppTopBar,
    AppMenu,
    AppConfig,
    AppFooter,
  },
};
</script>

<style lang="scss">
@import "./App.scss";

.layout-sidebar {
  width: 250px; /* Ajusta este valor según lo necesites */
  /* Puedes añadir más estilos aquí para personalizar el menú */
}

.layout-static .layout-main-container {
  margin-left: 250px; /* Asegúrate de que este valor coincida con el ancho del sidebar */
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 991px) {
  .layout-sidebar {
    width: 200px; /* O un valor más pequeño para pantallas móviles */
  }
  .layout-static .layout-main-container {
    margin-left: 200px;
  }
}
</style>



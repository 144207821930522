import { http } from './http';

export default {
 
  // Obtener comentarios por product_id---transicional
  obtenerComentariosPorProductoTransi(productoTransiId) {
    return http().get(`/v1/comments?producto_transi_id=${productoTransiId}`);
  },
  
 // Obtener comentarios por user_id
 obtenerComentariosPorUsuario(userId) {
    return http().get(`/v1/commentstransi/user/${userId}`);
  }
  
 }

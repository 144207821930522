
// ajustar la ruta según tu estructura de carpetas
import { http } from './http';

export default {
    // Obtener la lista de usuarios
    obtenerUsuarios() {
      return http().get('/v1/usuario');
    },
  
    // Obtener un usuario por ID
    obtenerUsuarioPorId(id) {
      return http().get(`/v1/usuario/${id}`);
    },
  
    // Crear un nuevo usuario
    crearUsuario(usuarioData) {
      return http().post('/v1/usuario', usuarioData);
    },
  
    // Actualizar un usuario por ID
    actualizarUsuario(id, usuarioData) {
      return http().put(`/v1/usuario/${id}`, usuarioData);
    },
  
    
    // Eliminar un usuario por ID
    eliminarUsuario(id) {
      return http().delete(`/v1/usuario/${id}`);
    },
    cambiarContrasena(data) {
      return http().post('/v1/usuario/cambiar-contrasena', data);
  },


    
     // Obtener la lista de usuarios ordenada por nombres
     async obtenerUsuariosOrdenadosPorNombres() {
      try {
        const response = await http().get('/v1/usuario');
        const usuarios = response.data;
  
        // Añadir propiedad 'nombreCompleto' a cada usuario
        usuarios.forEach(usuario => {
          usuario.nombreCompleto = `${usuario.nombres} ${usuario.apellidos}`;
        });
  
        // Ordenar la lista de usuarios por nombres completos
        usuarios.sort((a, b) => {
          const nombreA = a.nombreCompleto.toUpperCase();
          const nombreB = b.nombreCompleto.toUpperCase();
          if (nombreA < nombreB) return -1;
          if (nombreA > nombreB) return 1;
          return 0;
        });
  
        return usuarios;
      } catch (error) {
        throw error;
      }
    },

  obtenerTotalUsuariosRol2() {
    return http().get('/v1/total-usuarios');
},

obtenerActividadUsuarios() {
  return http().get('/v1/actividad-usuarios');
}
};

  
  

  
<template>
    <div class="estadisticas-transi-container">
      <h1 class="titulo">Estadísticas de Comentarios Transi</h1>
  
      <router-link to="/estadisticas">
        <button type="button" class="btn btn-primary mb-4">
          <i class="bi bi-arrow-left-circle"></i> Regresar
        </button>
      </router-link>
  
      <div class="chart-container" ref="totalSentimientosTransiRef">
      <div class="header-container">
        <h2>Sentimientos Totales de Productos Transicionales</h2>
        <button type="button" class="btn btn-success" @click="downloadTotalSentimientosTransiPDF">
          <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
        </button>
      </div>
      <Pie v-if="totalSentimientosData && totalSentimientosData.datasets[0].data.length" 
           :data="totalSentimientosData" :options="chartOptions" />
    </div>

    <div class="chart-container" ref="comentariosUsuarioTransiRef">
      <h2>Comentarios por Usuario Transicionales</h2>
      <div class="form-group mb-4">
        <label for="userSelect" class="form-label">Seleccione un Usuario:</label>
        <select id="userSelect" class="form-control" v-model="selectedUserId" @change="updateComentariosUsuario">
          <option v-for="user in usuarios" :key="user.id" :value="user.id">{{ user.nombreCompleto }}</option>
        </select>
      </div>
      <button type="button" class="btn btn-success mt-2" @click="downloadComentariosUsuarioTransiPDF">
        <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
      </button>
      <Bar v-if="comentariosUsuarioData && comentariosUsuarioData.datasets[0].data.length" 
           :data="comentariosUsuarioData" :options="chartOptions" />
    </div>

    <div class="chart-container" ref="sentimientosProductoTransiRef">
      <h2>Sentimientos por Producto Transicionales</h2>
      <div class="form-group mb-4">
        <label for="productSelect" class="form-label">Seleccione un Producto:</label>
        <select id="productSelect" class="form-control" v-model="selectedProductoTransiId" @change="updateSentimientosProducto">
          <option v-for="product in productos" :key="product.id" :value="product.id">{{ product.id }}. {{ product.nombre }}</option>
        </select>
      </div>
      <button type="button" class="btn btn-success mt-2" @click="downloadSentimientosProductoTransiPDF">
        <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
      </button>
      <Bar v-if="sentimientosProductoData && sentimientosProductoData.datasets[0].data.length" 
           :data="sentimientosProductoData" :options="chartOptions" />
    </div>


    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import { Pie, Bar } from 'vue-chartjs';
  import comentarioService from '@/services/comentario.service';
  import usuarioService from '@/services/usuario.service';
  import productoTransiService from '@/services/productotransi.service';
  import { Chart as ChartJS, Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale } from 'chart.js';
  import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
  ChartJS.register(Title, Tooltip, Legend, ArcElement, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'EstadisticasComentarioTransiView',
    components: {
      Pie,
      Bar
    },
    setup() {
    const totalSentimientosTransiRef = ref(null);
    const comentariosUsuarioTransiRef = ref(null);
    const sentimientosProductoTransiRef = ref(null);

      const totalSentimientosData = ref(null);
      const comentariosUsuarioData = ref(null);
      const sentimientosProductoData = ref(null);
      const loaded = ref(false);
  
      const usuarios = ref([]);
      const productos = ref([]);
      const selectedUserId = ref(null);
      const selectedProductoTransiId = ref(null);
  
      const chartOptions = {
        responsive: true,
        aspectRatio: 3,
        plugins: {
          legend: {
            labels: {
              color: 'rgb(75, 192, 192)',
              font: {
                size: 14
              }
            }
          },
          tooltip: {
            backgroundColor: 'rgba(0,0,0,0.7)',
            titleFont: {
              size: 16
            },
            bodyFont: {
              size: 14
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          },
          y: {
            ticks: {
              color: 'rgba(75, 192, 192, 1)',
              font: {
                size: 14
              }
            }
          }
        }
      };
  
      const procesarDatos = (comentarios) => {
        const totalSentimientos = { positive: 0, negative: 0, neutral: 0 };
  
        comentarios.forEach(comentario => {
          if (comentario.producto_transi_id) {
            totalSentimientos[comentario.sentiment]++;
          }
        });
  
        totalSentimientosData.value = {
          labels: ['Positive', 'Negative', 'Neutral'],
          datasets: [{
            data: [totalSentimientos.positive, totalSentimientos.negative, totalSentimientos.neutral],
            backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
          }]
        };
  
        loaded.value = true;
      };
  
      const obtenerComentarios = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorTipo('transi');
          procesarDatos(response.data);
        } catch (error) {
          console.error("Error al obtener los comentarios:", error);
        }
      };
  
      const updateComentariosUsuario = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorUsuario(selectedUserId.value);
          const comentarios = response.data;
  
          const sentimientos = { positive: 0, negative: 0, neutral: 0 };
          comentarios.forEach(comentario => {
            if (comentario.producto_transi_id) {
              sentimientos[comentario.sentiment]++;
            }
          });
  
          comentariosUsuarioData.value = {
            labels: ['Positive', 'Negative', 'Neutral'],
            datasets: [{
              label: `Usuario ${selectedUserId.value}`,
              data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
              backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
            }]
          };
  
          loaded.value = true;
        } catch (error) {
          console.error("Error al obtener los comentarios por usuario:", error);
        }
      };
  
      const updateSentimientosProducto = async () => {
        try {
          const response = await comentarioService.obtenerComentariosPorProductoTransi(selectedProductoTransiId.value);
          const comentarios = response.data;
  
          const sentimientos = { positive: 0, negative: 0, neutral: 0 };
          comentarios.forEach(comentario => {
            sentimientos[comentario.sentiment]++;
          });
  
          sentimientosProductoData.value = {
            labels: ['Positive', 'Negative', 'Neutral'],
            datasets: [{
              label: `Producto ${selectedProductoTransiId.value}`,
              data: [sentimientos.positive, sentimientos.negative, sentimientos.neutral],
              backgroundColor: ['#4caf50', '#f44336', '#ffeb3b']
            }]
          };
  
          loaded.value = true;
        } catch (error) {
          console.error("Error al obtener los comentarios por producto:", error);
        }
      };

      const downloadPDF = async (refElement, filename, descripcion) => {
      try {
        const pdf = new jsPDF('p', 'mm', 'letter');
        const pageWidth = pdf.internal.pageSize.getWidth();
        const headerHeight = 30;

        const headerImg = require('@/assets/cabecera.jpg');
        const footerImg = require('@/assets/piepagina.jpg');

        const chartContainer = refElement.value;
        if (!chartContainer) throw new Error('Contenedor del gráfico no encontrado');

        const button = chartContainer.querySelector('button');
        if (button) button.style.display = 'none'; 

        const canvas = await html2canvas(chartContainer);
        const imgData = canvas.toDataURL('image/png');
        if (button) button.style.display = ''; 

        pdf.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);

        const imgWidth = pageWidth - 40;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        pdf.addImage(imgData, 'PNG', 20, headerHeight + 10, imgWidth, imgHeight);

        pdf.setFontSize(12);
        pdf.text(descripcion, 20, headerHeight + 20 + imgHeight + 10);
        pdf.addImage(footerImg, 'PNG', 0, pdf.internal.pageSize.getHeight() - 35, pageWidth, 35);
        pdf.save(filename);
      } catch (error) {
        console.error('Error al generar el PDF:', error);
      }
    };

    const downloadTotalSentimientosTransiPDF = () => {
      const data = totalSentimientosData.value.datasets[0].data;
      const total = data.reduce((a, b) => a + b, 0);

      const descripcion = `
Descripción del gráfico:
- Comentarios positivos: ${data[0]} (${((data[0] / total) * 100).toFixed(2)}%)
- Comentarios negativos: ${data[1]} (${((data[1] / total) * 100).toFixed(2)}%)
- Comentarios neutrales: ${data[2]} (${((data[2] / total) * 100).toFixed(2)}%)
Total de comentarios: ${total}
      `;

      downloadPDF(totalSentimientosTransiRef, 'sentimientos_transi.pdf', descripcion);
    };

    const downloadComentariosUsuarioTransiPDF = () => {
      const data = comentariosUsuarioData.value.datasets[0].data;
      const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
      `;

      downloadPDF(comentariosUsuarioTransiRef, 'comentarios_usuario_transi.pdf', descripcion);
    };

    const downloadSentimientosProductoTransiPDF = () => {
      const data = sentimientosProductoData.value.datasets[0].data;
      const descripcion = `
Descripción del gráfico:
- Positivos: ${data[0]}
- Negativos: ${data[1]}
- Neutrales: ${data[2]}
      `;

      downloadPDF(sentimientosProductoTransiRef, 'sentimientos_producto_transi.pdf', descripcion);
    };

  
      onMounted(async () => {
        await obtenerComentarios();
        usuarios.value = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
        const productosResponse = await productoTransiService.obtenerProductostransi();
        productos.value = productosResponse.data;
      });
  
      return {
       totalSentimientosTransiRef,
       comentariosUsuarioTransiRef,
       sentimientosProductoTransiRef,
        totalSentimientosData,
        comentariosUsuarioData,
        sentimientosProductoData,
        chartOptions,
        loaded,
        usuarios,
        productos,
        selectedUserId,
        selectedProductoTransiId,
        updateComentariosUsuario,
        updateSentimientosProducto,
        downloadTotalSentimientosTransiPDF,
        downloadComentariosUsuarioTransiPDF,
        downloadSentimientosProductoTransiPDF
      };
    }
  };
  </script>
  
  <style scoped>
  .estadisticas-transi-container {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .titulo {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .chart-container {
    margin-bottom: 40px;
  }
  
  .chart-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  .btn-primary {
    display: flex;
    align-items: center;
  }
  
  .btn-primary i {
    margin-right: 0.5rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  </style>
  
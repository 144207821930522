<template>
    <div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card h-full p-0 overflow-hidden flex flex-column">
                <div class="flex align-items-center p-3">
                    <i class="fas fa-table text-6xl text-purple-500"></i> 
                    
                    <div class="ml-3">
                        <span class="text-purple-500 block white-space-nowrap">
                            MINIMALISTA
                        </span>
                        <span class="text-purple-500 block text-4xl font-bold">
                            {{ totalProductosMini }}
                        </span>
                    </div>
                </div>
                <img src="@/assets/img/cartas/interactions.svg" class="w-full mt-auto" alt="interacciones">
            </div>
        </div>
    </div>
</template>

<script>
import productominiService from '@/services/productomini.service';
export default {
    props: {
        activeProduMini: Number
    },
    data() {
        return {
            totalProductosMini: 0 // Inicializa el total como 0
        };
    },
    mounted() {
        this.obtenerTotalProductosMini(); // Llama a la función al cargar el componente
    },
    methods: {
        async obtenerTotalProductosMini() {
  try {
    const response = await productominiService.obtenerTotalProductosMini();
    if (response && response.data && response.data.total_productos_mini) {
      this.totalProductosMini = response.data.total_productos_mini;
    } else {
        console.log('Respuesta completa:', response);

    }
  } catch (error) {
    console.error('Error al obtener el total :', error);
  }
}

    }
};
</script>

  

 

  
<template>
    <div>
 
      <div>
        <h1>Gráfico de Posibles Clientes</h1>
        <router-link to="/estadisticas"> 
    <button type="button" class="btn btn-primary">Regresar</button>
  </router-link>
        <div class="row mt-3">
          <div class="card border border-dark">
            <div class="card-header bg-dark"></div>
            <div class="card-body">
              <Bar v-if="loaded" :options="chartOptions" :data="chartData" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { Bar } from 'vue-chartjs';
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
  } from 'chart.js';
  import posibleService from '@/services/posible.service';
  
  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);
  
  export default {
    name: 'BarChart',
    components: { Bar },
    data: () => ({
        loaded: false,
        chartData: null,
        chartOptions: {
          responsive: true,
          aspectRatio: 3,
          devicePixelRatio: 4,
          scales: {
            x: {
              grid: {
                display: false,
              },
              ticks: {
                color: 'red',
              },
            },
            y: {
              grid: {
                display: true,
              },
            },
          },
      
      }
    }),
    async mounted() {
        this.loaded = false
      try {
        const response = await posibleService.obtenerPosibles();
        const posibles = response.data;
  
        // Llenar las categorías
        const potencial = posibles.filter((posible) => posible.rango === 'potencial').length;
        const neutro = posibles.filter((posible) => posible.rango === 'neutro').length;
        const bajo = posibles.filter((posible) => posible.rango === 'bajo').length;
  
        // Actualizar los datos de la gráfica
        this.chartData = {
            //hacer lo mismo que data [user_id]
          labels: ['Grafica'],
          datasets: [
            {
              label: 'Potencial',
              data: [potencial],
              backgroundColor: '#FF0000',
            },
            {
              label: 'Neutro',
              data: [neutro],
              backgroundColor: '#969D23',
            },
            {
              label: 'Bajo',
              data: [bajo],
              backgroundColor: '#239D67',
            },
          ],
         
        }
        this.loaded = true
        
      } catch (error) {
        console.error('Error al obtener los datos de posibles', error);
      }
    },
  };
  </script>
  
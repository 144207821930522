<template>
  <div id="appsss">
  
    

 

      <transition name="fade">
      <div class="modal-overlay" v-if="showModal">

    </div>
    </transition>

    <transition name="fade">
            <div class="modal" v-if="showModal">
        <h1>Titulo</h1>
        <p>Contenido del modal</p>
        <button class="button" @click= "showModal=false">Cerrar Modal</button>
      </div>
    </transition>
    
    <button class="button" @click="showModal=true">Abrir Modal</button>
  </div>
</template>
<script>
export default {
    
    
       
     name: "appsss",
       

  data() {
    return {
      showModal: false
    };
}
};
</script>


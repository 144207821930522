<template>
    <div>
        <div class="col-12 lg:col-6 xl:col-3">
            <div class="card p-0 overflow-hidden flex flex-column">
                <div class="flex align-items-center p-3">
                    <i class="pi pi-users text-6xl text-blue-500"></i>
                    <div class="ml-3">
                        <span class="text-blue-500 block white-space-nowrap">
                            USUARIOS 
                        </span>
                        <span class="text-blue-500 block text-4xl font-bold">
                            {{ totalUsuariosRol2 }} <!-- Mostrar el total aquí -->
                        </span>
                    </div>
                </div>
                <img src="@/assets/img/cartas/users.svg" class="w-full" alt="usuarios">
            </div>
        </div>
    </div>
</template>


// Tu componente Vue (puede ser el mismo archivo donde tienes la plantilla)

<script>
import usuarioService from '@/services/usuario.service';

export default {
    props: {
        activeUsers: Number
    },
    data() {
        return {
            totalUsuariosRol2: 0 // Inicializa el total como 0
        };
    },
    mounted() {
        this.obtenerTotalUsuariosRol2(); // Llama a la función al cargar el componente
    },
    methods: {
        async obtenerTotalUsuariosRol2() {
  try {
    const response = await usuarioService.obtenerTotalUsuariosRol2();
    if (response && response.data && response.data.total_usuarios_rol_2) {
      this.totalUsuariosRol2 = response.data.total_usuarios_rol_2;
    } else {
        console.log('Respuesta completa:', response);

    }
  } catch (error) {
    console.error('Error al obtener el total de usuarios:', error);
  }
}

    }
};
</script>

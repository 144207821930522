<template>
  <div>
    <h1 class="mb-4 text-center">Clientes Potenciales</h1>

    <router-link to="/estadisticas">
      <button type="button" class="btn btn-secondary mb-4">
        <i class="bi bi-arrow-left-circle"></i> Regresar
      </button>
    </router-link>

    <button type="button" class="btn btn-success mt-4" @click="downloadPDF">
      <i class="bi bi-file-earmark-pdf"></i> Descargar PDF
    </button>
    <!-- Select para elegir un cliente -->
    <div class="form-group mb-4">
      <label for="userSelect" class="form-label">Seleccione un Cliente:</label>
      <select id="userSelect" class="form-control" v-model="selectedUserId">
        <option v-for="user in usuarios" :key="user.id" :value="user.id">
          {{ user.id }} - {{ user.nombreCompleto }}
        </option>
      </select>
    </div>

    <!-- Botones para agregar el cliente a la tabla correspondiente -->
    <div class="d-flex justify-content-around mb-4">
      <button class="btn btn-success" @click="agregarCliente('alto')">Agregar a Alto</button>
      <button class="btn btn-warning" @click="agregarCliente('neutro')">Agregar a Neutro</button>
      <button class="btn btn-danger" @click="agregarCliente('bajo')">Agregar a Bajo</button>
    </div>

    <!-- Tablas de clientes una debajo de otra -->
    <div class="container">
      <div v-for="(clientes, tipo) in categoriasClientes" :key="tipo" class="mb-4">
        <div class="card shadow-sm">
          <div class="card-header bg-dark text-white text-center">
            {{ tipo.toUpperCase() }}
          </div>
          <div class="card-body table-responsive p-0">
            <table class="table table-hover table-bordered mb-0">
              <thead class="table-dark">
                <tr>
                  <th>Nombre</th>
                  <th>Tradicional</th>
                  <th>Minimalista</th>
                  <th>Transicional</th>
                  <th>Acciones</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cliente in clientes" :key="cliente.id">
                  <td>{{ cliente.nombreCompleto }}</td>
                  <td class="text-center">{{ cliente.clicks?.tradicional || 0 }}</td>
                  <td class="text-center">{{ cliente.clicks?.minimalista || 0 }}</td>
                  <td class="text-center">{{ cliente.clicks?.transicional || 0 }}</td>
                  <td class="text-center">
                    <button class="btn btn-danger btn-sm" @click="eliminarCliente(tipo, cliente.id)">
                      <i class="bi bi-trash"></i> Eliminar
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import usuarioService from '@/services/usuario.service.js';
import contadorService from '@/services/contador.service.js';
import categoriaClienteService from '@/services/categoriaCliente.service.js';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

export default {
  data() {
    return {
      usuarios: [], // Usuarios registrados
      categoriasClientes: {
        alto: [],
        neutro: [],
        bajo: []
      },
      selectedUserId: null // ID del usuario seleccionado
    };
  },
  async mounted() {
    await this.cargarUsuarios(); // Cargar usuarios al montar el componente
    await this.cargarCategoriasClientes(); // Cargar categorías de clientes al montar el componente
  },
  methods: {
    async cargarUsuarios() {
      try {
        this.usuarios = await usuarioService.obtenerUsuariosOrdenadosPorNombres();
      } catch (error) {
        console.error('Error al cargar los usuarios:', error);
      }
    },
    async cargarContadores(userId) {
      try {
        const response = await contadorService.getContador(userId);
        return response.data || {
          contadortradicional: 0,
          contadorminimalista: 0,
          contadortransicional: 0
        };
      } catch (error) {
        console.error('Error al obtener los contadores del usuario:', error);
        return { contadortradicional: 0, contadorminimalista: 0, contadortransicional: 0 };
      }
    },
    async cargarCategoriasClientes() {
  try {
    const response = await categoriaClienteService.obtenerCategoriasClientes();

    // Asigna cada cliente a la categoría correspondiente
    response.data.forEach(cliente => {
      const categoria = cliente.categoria;
      const clienteConContadores = {
        id: cliente.id, // Este es el ID de la tabla `categoria_cliente`
        user_id: cliente.user_id, // También guarda el user_id si lo necesitas
        nombreCompleto: this.usuarios.find(user => user.id === cliente.user_id)?.nombreCompleto || 'N/A',
        clicks: {
          tradicional: cliente.contador_tradicional,
          minimalista: cliente.contador_minimalista,
          transicional: cliente.contador_transicional
        }
      };
      this.categoriasClientes[categoria].push(clienteConContadores);
    });
  } catch (error) {
    console.error('Error al cargar las categorías de clientes:', error);
  }
},

    async agregarCliente(categoria) {
      const cliente = this.usuarios.find(user => user.id === this.selectedUserId);
      if (cliente && !this.categoriasClientes[categoria].some(c => c.id === cliente.id)) {
        const contadores = await this.cargarContadores(cliente.id);

        // Crear los datos que se enviarán a la API
        const data = {
          user_id: cliente.id,
          categoria: categoria,
          contador_tradicional: contadores.contadortradicional || 0,
          contador_minimalista: contadores.contadorminimalista || 0,
          contador_transicional: contadores.contadortransicional || 0
        };

        // Llamada a la API para guardar en la base de datos usando el servicio
        const response = await categoriaClienteService.crearCategoriaCliente(data);

        // Asegúrate de que `clicks` esté definido con valores predeterminados
        const clienteConContadores = {
          ...cliente,
          clicks: {
            tradicional: data.contador_tradicional,
            minimalista: data.contador_minimalista,
            transicional: data.contador_transicional
          }
        };

        // Agregar el cliente a la categoría en el estado local
        this.categoriasClientes[categoria].push(clienteConContadores);
      }
    },

    async eliminarCliente(tipo, categoriaClienteId) {
  try {
    await categoriaClienteService.eliminarCategoriaCliente(categoriaClienteId);
    this.categoriasClientes[tipo] = this.categoriasClientes[tipo].filter(
      cliente => cliente.id !== categoriaClienteId
    );
  } catch (error) {
    console.error("Error al eliminar el cliente de la categoría:", error);
  }
},

async downloadPDF() {
      const pdf = new jsPDF('p', 'mm', 'letter');
      const pageWidth = pdf.internal.pageSize.getWidth();
      const pageHeight = pdf.internal.pageSize.getHeight();
      const headerHeight = 30;
      const footerHeight = 35;

      const headerImg = require('@/assets/cabecera.jpg');
      const footerImg = require('@/assets/piepagina.jpg');

      // Función para añadir la cabecera
      const addHeader = (doc) => {
        doc.addImage(headerImg, 'PNG', 0, 0, pageWidth, headerHeight);
      };

      // Función para añadir el pie de página
      const addFooter = (doc) => {
        doc.addImage(footerImg, 'PNG', 0, pageHeight - footerHeight, pageWidth, footerHeight);
      };

      // Añadir la cabecera y el pie de página
      addHeader(pdf);
      addFooter(pdf);

      // Título del reporte
      pdf.setFontSize(14);
      pdf.text(`Reporte de Clientes Potenciales`, 20, headerHeight + 10);

      let yOffset = headerHeight + 20;

      // Generar tablas para cada categoría
      for (const [categoria, clientes] of Object.entries(this.categoriasClientes)) {
        // Añadir título de categoría
        pdf.setFontSize(12);
        pdf.setFont(undefined, 'bold');
        pdf.text(`${categoria.toUpperCase()}`, 15, yOffset);
        yOffset += 8;

        // Añadir encabezados de la tabla
        pdf.setFontSize(10);
        pdf.text('Nombre', 15, yOffset);
        pdf.text('Tradicional', 75, yOffset);
        pdf.text('Minimalista', 115, yOffset);
        pdf.text('Transicional', 155, yOffset);
        yOffset += 6;

        // Añadir cada cliente de la categoría
        pdf.setFont(undefined, 'normal');
        clientes.forEach(cliente => {
          pdf.text(cliente.nombreCompleto, 15, yOffset);
          pdf.text(String(cliente.clicks.tradicional), 75, yOffset, { align: 'center' });
          pdf.text(String(cliente.clicks.minimalista), 115, yOffset, { align: 'center' });
          pdf.text(String(cliente.clicks.transicional), 155, yOffset, { align: 'center' });
          yOffset += 6;
        });

        // Añadir espacio entre categorías
        yOffset += 10;
        if (yOffset + footerHeight > pageHeight) {
          pdf.addPage();
          yOffset = headerHeight + 20;
          addHeader(pdf);
          addFooter(pdf);
        }
      }

      // Guardar el PDF
      pdf.save('reporte_clientes_potenciales.pdf');
    }
  

  }
};
</script>

<style>
.container {
  max-width: 800px;
  margin: 0 auto;
}

.card {
  border-radius: 8px;
  overflow: hidden;
}

.table-hover tbody tr:hover {
  background-color: #f1f1f1;
}

.table {
  margin-bottom: 0;
}

.card-header {
  font-weight: bold;
}

button {
  width: auto;
  padding: 5px 10px;
  font-size: 0.9rem;
}

.table td,
.table th {
  vertical-align: middle;
  text-align: center;
}

.btn-sm {
  font-size: 0.8rem;
  padding: 4px 8px;
}

.table .btn-danger {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
}

</style>

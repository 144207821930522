<template>
    <div class="surface-0 flex align-items-center justify-content-center min-vh-100 position-relative">
      <img alt="fondo" src="@/assets/crmimagen.jpg" class="background-image" />
      <div class="card p-4 shadow-lg" style="border-radius: 12px; max-width: 600px; width: 100%; background: rgba(255, 255, 255, 0.9); z-index: 2;">
        <div class="text-center mb-4">
          <img src="@/assets/logoVeraJaus.png" alt="logo" height="50" class="mb-3" />
          <h2 class="h4 mb-1">Crear una cuenta</h2>
          <p class="text-muted">Es rápido y fácil.</p>
        </div>
        <form @submit.prevent="registrarUsuario"  >
          <div class="row mb-3">
            <div class="col-md-6 mb-3 mb-md-0">
              <InputText v-model="usuarioRegistro.nombres" placeholder="Nombres" class="form-control form-control-lg" required />
              <small v-if="errores.nombres" class="text-danger">{{ errores.nombres }}</small>
            </div>
            <div class="col-md-6">
              <InputText v-model="usuarioRegistro.apellidos" placeholder="Apellidos" class="form-control form-control-lg" required />
              <small v-if="errores.apellidos" class="text-danger">{{ errores.apellidos }}</small>
            </div>
          </div>
          <div class="mb-3">
            <InputText v-model="usuarioRegistro.email" placeholder="Correo Electrónico" class="form-control form-control-lg" required />
            <small v-if="errores.email" class="text-danger">{{ errores.email }}</small>
          </div>
          <div class="mb-3 position-relative">
            <Password 
              v-model="usuarioRegistro.password" 
              @focus="mostrarSugerencia = true" 
              @blur="mostrarSugerencia = false"
              placeholder="Contraseña" 
              class="form-control form-control-lg" 
              toggleMask 
              required 
             
            />
            <small v-if="errores.password" class="text-danger">{{ errores.password }}</small>
            <div v-if="mostrarSugerencia" class="password-tooltip">
              Sugerencia: Usa al menos 7 caracteres, incluyendo letras y números.
            </div>
          </div>
          <div class="mb-3 position-relative">
            <Password 
              v-model="usuarioRegistro.c_password" 
              @focus="mostrarSugerenciaConfirmacion = true" 
              @blur="mostrarSugerenciaConfirmacion = false"
              placeholder="Confirmar Contraseña" 
              class="form-control form-control-lg" 
              toggleMask 
              required 
               
            />
            <small v-if="errores.c_password" class="text-danger">{{ errores.c_password }}</small>
            <div v-if="mostrarSugerenciaConfirmacion" class="password-tooltip">
              Asegúrate de que coincida con la contraseña.
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6 mb-3 mb-md-0">
              <InputText v-model="usuarioRegistro.telefono" placeholder="Teléfono" class="form-control form-control-lg" required />
              <small v-if="errores.telefono" class="text-danger">{{ errores.telefono }}</small>
            </div>
            <div class="col-md-6">
              <InputText v-model="usuarioRegistro.direccion" placeholder="Dirección" class="form-control form-control-lg" required />
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-md-6 mb-3 mb-md-0">
              <InputText v-model="usuarioRegistro.empresa" placeholder="Empresa" class="form-control form-control-lg" required />
            </div>
            <div class="col-md-6">
              <InputText v-model="usuarioRegistro.apodo" placeholder="Apodo" class="form-control form-control-lg" required />
            </div>
          </div>
          <input type="hidden" v-model="usuarioRegistro.rol" />
          <div v-if="errorMessageRegistro" class="alert alert-danger text-center">{{ errorMessageRegistro }}</div>
          <div class="d-flex justify-content-center">
            <Button label="Registrarse" type="submit" class="btn btn-success btn-lg mx-2" />
            <Button label="Regresar" @click="cerrarRegistro" class="btn btn-secondary btn-lg mx-2" />
          </div>
        </form>
      </div>

      <!-- Modal de éxito -->
    <div v-if="mostrarModalExito" class="modal-overlay">
      <div class="modal-content animate-fade">
        <i class="pi pi-check-circle modal-icon"></i>
        <h3 class="modal-title">Cuenta creada con éxito</h3>
      </div>
    </div>

      <Toast ref="toast"></Toast>
    </div>
  </template>
  
  <script>
  import InputText from 'primevue/inputtext';
  import Password from 'primevue/password';
  import Button from 'primevue/button';
  import Toast from 'primevue/toast';
  import { useToast } from 'primevue/usetoast';
  import * as authService from '../../services/auth.service';
  
  export default {
    components: {
      InputText,
      Password,
      Button,
      Toast,
    },
    data() {
      return {
        usuarioRegistro: {
          nombres: '',
          apellidos: '',
          telefono: '',
          direccion: '',
          empresa: '',
          apodo: '',
          email: '',
          password: '',
          c_password: '',
          rol: '2',
        },
        errorMessageRegistro: '',
        errores: {}, // objeto para almacenar errores específicos de cada campo
        mostrarSugerencia: false, // para mostrar la sugerencia de contraseña
        mostrarSugerenciaConfirmacion: false, // para mostrar la sugerencia en confirmar contraseña
        mostrarModalExito: false, // controla la visibilidad del modal de éxito
      };
    },
    methods: {
      validarCampos() {
        this.errores = {}; // Reiniciar errores
  
        // Validar nombres solo letras
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(this.usuarioRegistro.nombres)) {
          this.errores.nombres = 'Solo se permiten letras en este campo.';
        }
  
        // Validar apellidos solo letras
        if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(this.usuarioRegistro.apellidos)) {
          this.errores.apellidos = 'Solo se permiten letras en este campo.';
        }
  
        // Validar teléfono solo números
        if (!/^[0-9]+$/.test(this.usuarioRegistro.telefono)) {
          this.errores.telefono = 'Solo se permiten números en este campo.';
        }
  
        // Validar formato de email
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(this.usuarioRegistro.email)) {
          this.errores.email = 'Ingresa un correo electrónico válido.';
        }
  
        // Validar longitud de la contraseña
        if (this.usuarioRegistro.password.length < 7) {
          this.errores.password = 'La contraseña debe tener al menos 7 caracteres.';
        }
  
        return Object.keys(this.errores).length === 0; // Retorna true si no hay errores
      },
      async registrarUsuario() {
        if (!this.validarCampos()) {
          this.errorMessageRegistro = 'Corrige los errores antes de enviar el formulario.';
          return;
        }
  
        try {
          if (this.usuarioRegistro.password !== this.usuarioRegistro.c_password) {
            this.errorMessageRegistro = 'Las contraseñas no coinciden.';
            return;
          }
          this.usuarioRegistro.rol = 2; // Asignar el rol cliente (id 2) al usuario
          const response = await authService.registro(this.usuarioRegistro);
          if (response && response.status === 201) {
            this.mostrarModalExito = true; // Mostrar el modal de éxito
          setTimeout(() => {
            this.mostrarModalExito = false; // Ocultar el modal después de 2 seg
            this.$router.push('/login');
          }, 2000);
          }
        } catch (error) {
          this.errorMessageRegistro = 'Error al registrar el usuario.';
          console.error(error);
        }
      },
      cerrarRegistro() {
        this.$router.push('/login');
      },
    },
    mounted() {
      this.$toast = useToast();
      this.usuarioRegistro.password = ''; // Asegúrate de que los campos de contraseña estén vacíos al iniciar
      this.usuarioRegistro.c_password = '';
    },
  };
  </script>
  
  <style scoped>
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    z-index: 1;
  }
  .card {
    background: rgba(255, 255, 255, 0.9);
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
  .form-control-lg {
    font-size: 1.2rem;
    padding: 0.75rem;
    border-radius: 0.5rem;
  }
  .btn-lg {
    padding: 0.75rem;
    font-size: 1.2rem;
    border-radius: 0.5rem;
  }
  .alert {
    margin-top: 1rem;
  }
  .d-flex .btn {
    min-width: 150px;
  }
  .text-danger {
    color: red;
    font-size: 0.9rem;
  }
  .password-tooltip {
    position: absolute;
    top: -1.5rem;
    left: 0;
    background: #f0f0f0;
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.8rem;
    color: #555;
    z-index: 10;
  }
  .p-toast {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .p-toast-message-success {
    display: flex;
    justify-content: center;
    align-items: center;
  }

/* Estilos del modal de éxito */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}
.modal-content {
  background: #ffffff;
  padding: 2rem;
  border-radius: 12px;
  text-align: center;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.5s ease-out;
}
.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
  color: #4CAF50;
  margin: 0;
}
.modal-icon {
  font-size: 3rem;
  color: #4CAF50;
  margin-bottom: 0.5rem;
}
.animate-fade {
  animation: fadeInOut 1s ease-in-out;
}

@keyframes fadeInOut {
  0% { opacity: 0; transform: scale(0.9); }
  50% { opacity: 1; transform: scale(1); }
  100% { opacity: 0; transform: scale(0.9); }
}
  </style>
  

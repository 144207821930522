<template>
  <div class="chat-container">
    <div class="sidebar">
      <div class="profile">
        <img :src="user.profileImage" alt="Profile" class="profile-image">
        <h2>{{ user.name }}</h2>
      </div>
      <input type="text" v-model="searchQuery" placeholder="Buscar" class="search">
      <ul class="contacts">
        <li v-for="contact in filteredContacts" :key="contact.id" class="contact">
          <img :src="contact.profileImage" alt="Profile" class="contact-image">
          <div class="contact-info">
            <h3>{{ contact.name }}</h3>
            <p>{{ contact.lastMessage }}</p>
          </div>
          <span class="contact-time">{{ contact.lastActive }}</span>
        </li>
      </ul>
    </div>
    <div class="chat-window">
      <div class="chat-header">
        <img :src="currentChat.profileImage" alt="Profile" class="chat-header-image">
        <div class="chat-header-info">
          <h2>{{ currentChat.name }}</h2>
          <p>Última actividad hace {{ currentChat.lastActive }}</p>
        </div>
      </div>
      <div class="chat-messages">
        <div v-for="message in currentChat.messages" :key="message.id" class="message" :class="{'sent': message.sentByUser}">
          <p>{{ message.text }}</p>
          <span class="message-time">{{ message.time }}</span>
        </div>
      </div>
      <div class="chat-input">
        <input type="text" v-model="newMessage" placeholder="Escribe un mensaje" @keydown.enter="sendMessage">
        <button @click="sendMessage">Enviar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      user: {
        name: 'Asia Javayant',
        profileImage: 'path/to/your/profile/image.jpg'
      },
      searchQuery: '',
      contacts: [
        // Sample contacts
        { id: 1, name: 'Ioni Bowcher', profileImage: 'path/to/contact/image1.jpg', lastMessage: 'Sed do eiusmod tempor...', lastActive: '2d' },
        // Add more contacts as needed
      ],
      currentChat: {
        name: 'Ioni Bowcher',
        profileImage: 'path/to/contact/image1.jpg',
        lastActive: '1 hora',
        messages: [
          { id: 1, text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit', time: '16:25', sentByUser: false },
          { id: 2, text: 'Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua', time: '16:26', sentByUser: false },
          // Add more messages as needed
        ]
      },
      newMessage: ''
    };
  },
  computed: {
    filteredContacts() {
      return this.contacts.filter(contact => contact.name.toLowerCase().includes(this.searchQuery.toLowerCase()));
    }
  },
  methods: {
    sendMessage() {
      if (this.newMessage.trim() !== '') {
        this.currentChat.messages.push({
          id: Date.now(),
          text: this.newMessage,
          time: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          sentByUser: true
        });
        this.newMessage = '';
      }
    }
  }
};
</script>

<style scoped>
.chat-container {
  display: flex;
  height: 100vh;
}

.sidebar {
  width: 25%;
  border-right: 1px solid #ccc;
  padding: 20px;
}

.profile {
  text-align: center;
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.search {
  width: 100%;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contacts {
  list-style: none;
  padding: 0;
}

.contact {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.contact-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.contact-info {
  flex-grow: 1;
}

.contact-time {
  color: #888;
}

.chat-window {
  width: 75%;
  display: flex;
  flex-direction: column;
}

.chat-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 20px;
}

.chat-header-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.chat-messages {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.message {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 5px;
  max-width: 60%;
}

.message.sent {
  align-self: flex-end;
  background-color: #e6f7ff;
}

.message-time {
  display: block;
  text-align: right;
  color: #888;
}

.chat-input {
  display: flex;
  padding: 20px;
  border-top: 1px solid #ccc;
}

.chat-input input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
}

.chat-input button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
</style>
